import React, { Component, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  createTheme,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useUser } from "../../../controllers/services/UseUser";
import LoadingBubbleOrganisms from "../../organisms/LoadingBubbleOrganisms";
import { render } from "react-dom";
import { useHistory, withRouter, Link } from "react-router-dom";
import { MyOrders } from "../MyOrder/MyOrders";
import { useAuth } from "../../../controllers/services/UseAuth";
import ButtonAppBar from "../../modules/AppBar";
import Footer from "../../modules/Footer";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(10),
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonFont: {
    fontSize: 16,
    paddingLeft: '0px'
  },
  AccountIcon: {
    marginRight: theme.spacing(2),
    fontSize: 40,
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledButton = withStyles({
  root: {
    padding: "20px",
    width: 220,
    lineHeight: 2,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    backgroundColor: theme.palette.primary.main,
  },
})(Button);

const customerInfoButton = "お客様情報の\nご確認･ご変更";
const orderInfoButton = "ご注文履歴の\nご確認";
const logoutButton = "ログアウト";

export default function MyAccount() {
  const history = useHistory();
  const classes = useStyles();
  const { useGetUser, useGetUserPoints } = useUser();
  const {
    data: User, 
    isLoading, 
    isError 
  } = useGetUser();
  const {
    data: Points,
    isLoading: pointsLoading,
    isError: pointsError,
  } = useGetUserPoints();
  const { useRequestLogoutMutation } = useAuth();
  const requestLogoutMutate = useRequestLogoutMutation().mutateAsync;
  const [email, setEmail] = useState("");

  if (isLoading && pointsLoading) {
    return (
      <ThemeProvider theme={theme}>
        <ButtonAppBar />
        <Container component="main" maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item className={classes.grid}>
              <Divider className={classes.divider} />
              <Typography component="h2" variant="h4">
                マイページ
              </Typography>
              <Divider className={classes.divider} />
              <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
              <div style={{ whiteSpace: "pre-line" }}></div>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
  } else if ( User || Points ) {
    const nameString = User
    ? User[0].displayName : "";
      // ? User[0].firstName != null
      //   ? User[0].firstName + " " + User[0].lastName
      //   : User[0].lastName
      // : "";
    return (
      <ThemeProvider theme={theme}>
        <ButtonAppBar />
        <Container component="main" maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item className={classes.grid}>
              <Divider className={classes.divider} />
              <Typography component="h2" variant="h4">
                マイページ
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body1" style={{ fontSize: 14 }}>
                { nameString }
                　様
              </Typography>
              <Typography variant="body1" style={{ fontSize: 14 }}></Typography>
              <Box sx={{ height: 30 }}></Box>
              <Typography variant="body1" style={{ fontSize: 14 }}>
                ご利用可能ポイント数
              </Typography>
              <Box sx={{ height: 10 }}></Box>
              <Typography>{Points.possession}ポイント</Typography>
              <Box sx={{ height: 30 }}></Box>
              {/* 改行を入れる */}
              <div style={{ whiteSpace: "pre-line" }}>
                <Link to="/myaccount/check" className="Link">
                  <StyledButton className={classes.button}>
                    <AccountCircleIcon className={classes.AccountIcon} />
                    <Typography className={classes.buttonFont}>
                      {customerInfoButton}
                    </Typography>
                  </StyledButton>
                </Link>
                <Link to="/myaccount/myorders" className="Link">
                  <StyledButton className={classes.button}>
                    <ShoppingBasketIcon className={classes.AccountIcon} />
                    <Typography className={classes.buttonFont}>
                      {orderInfoButton}
                    </Typography>
                  </StyledButton>
                </Link>
                <StyledButton
                  className={classes.button}
                  onClick={async () => {
                    await requestLogoutMutate();
                    history.push("/login");
                  }}
                >
                  <ExitToAppIcon className={classes.AccountIcon} />
                  <Typography className={classes.buttonFont}>
                    {logoutButton}
                  </Typography>
                </StyledButton>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }
}

import { commonHttpClient } from "./commonHttpClient";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useToast } from "../../components/molecules/toastMolecules";
import { useHistory } from "react-router-dom";

export const useMarketUser = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();
  const history = useHistory();

  const updateMarketUser = async ({
    email,
    firstName,
    lastName,
    phone,
    zip,
    city,
    province,
    address1,
    address2,
  }) => {
    await http.patch("/market-users/cafe", {
      email,
      firstName,
      lastName,
      phone,
      zip,
      city,
      province,
      address1,
      address2,
    });
  };

  const useUpdateMarketUser = () =>
    useMutation(updateMarketUser, {
      onSuccess: () => {
        history.push("/myaccount/change/success");
        setTimeout(() => {
          queryClient.invalidateQueries("myAccount");
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          if (e.message === "Request failed with status code 400") {
            showToast("error", "重複エラー", e.message, 3000);
          } else {
            showToast("error", "", e.message, 3000);
          }
        }
      },
    });

  return { useUpdateMarketUser };
};

/* eslint-disable */
import { 
  Container,
  Typography,
  // Link,
  Grid,
  Box,
  makeStyles,
  ThemeProvider,
  createTheme,
  withStyles 
} from '@material-ui/core';
import React from 'react';
import { Link } from "react-router-dom";

const theme = createTheme({
    palette: {
      primary: {
        light: '#e6eae6',
        main: '#d3d3d8',
        dark: '#c9caca',
        contrastText: '#626063',
      },
      secondary: {
        light: '#ff7f50',
        main: '#ff4500',
        dark: '#cc3600',
        contrastText: '#ffffff',
      },
    },
  });


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

// const StyledLink = withStyles((theme) => ({
//   root: {
//     color: theme.palette.primary.contrastText,
//    },
//   }))(Link);


const Copyright = () => {
  return(
          <Typography variant="body1" align="center">
            {'Copyright © '}
             LEAFcafe
            {' '}
            {new Date().getFullYear()}
            {'.'}
            </Typography>
  );
};


export default function Footer() {
  const classes = useStyles();
  return (
  <div>
   <ThemeProvider theme={theme}>
   <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto'}}
          >
      <Container maxWidth={false} className={classes.container}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={2} align="center" >
            {/* <StyledLink href="/agreement"> */}
            <Link to="/agreement"  className="Footer__Link">
              利用規約
            </Link>
          </Grid>
         <Grid item xs={12} sm={2} align="center">
            <Link to="/tradelow" className="Footer__Link">
              特定商取引法に基づく表示
             </Link>
           </Grid>
          <Grid item xs={12} sm={2} align="center">
              <Link to="/privacy" className="Footer__Link" >
              プライバシーポリシー
              </Link>
          </Grid>
          <Grid item xs={12} sm={2} align="center">
            {/* <StyledLink href="http://www.iwabuchi-net.co.jp/" target="_blank" color="primary" > */}
            <a href="http://www.iwabuchi-net.co.jp/"  target="_blank"　className="Footer__Link">
              会社概要
            </a>
          </Grid>
          <Grid item xs={12} sm={2} align="center">
            <Link to="/contact" className="Footer__Link" >
              お問い合わせ
            </Link>
          </Grid>
        </Grid>
            <Box pt={4} >
              <Copyright />
            </Box>
        </Container>
        </Box>
        </ThemeProvider>
  </div>

  )
}


import { useQuery, useQueryClient } from "react-query";
import { commonHttpClient } from "./commonHttpClient"

export const usePage = () => {
  const { http } = commonHttpClient();

  const getPages = async() => {
    let pages;
      await http.get(`/page`)
      .then((response) => {
        pages = response.data;
      })
      .catch((err) => {
        console.debug("ページ情報取得失敗:", err.response);
      });
      console.log(pages);
    return pages;
  };

  const useGetPages = () => useQuery("pagesInfo", getPages);

    return{
      useGetPages,
    }
}
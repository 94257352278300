import axios from 'axios';
import * as constants from '../../utils/CommonConstants';

export const commonHttpClient = () => {
  const http = axios.create({
    baseURL: constants.SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  http.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  http.interceptors.response.use((response) => {
    return response;
  });
  return { http };
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ButtonAppBar from "../../modules/AppBar";


const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  grid: {
    margin: "auto",
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(10),
    textAlign: "center",
  },
  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function AccountChangeDone() {
  const classes = useStyles();
  return(
      <ThemeProvider theme={theme}>
      <ButtonAppBar/>
        <Container component="main" maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item className={classes.grid}>
              <Typography component="h2" variant="h6">
              お客様情報の更新が完了いたしました。
              </Typography>
              <Link
                to="/myaccount"
                className="btn-border-bottom"
                style={{
                  margin: theme.spacing(4),
                }}
              >
                マイページに戻る
              </Link>
              <Link
                to="/myaccount/check"
                className="btn-border-bottom"
                style={{
                  margin: theme.spacing(4),
                }}
              >
                お客様情報へ戻る
              </Link>             
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
  );
  }


import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  createTheme,
  CssBaseline,
  Grid,
  Link,
  makeStyles,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../img/logo/LEAFcafe_logo.jpg";
import dummy from "../img/dummy.jpg";

import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../controllers/services/UseAuth";
import {
  LOGIN_INITIAL_VALUES,
  LOGIN_VALIDATION_SCHEMA,
} from "../../utils/validator";
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#89c997",
      main: "#79c06e",
      dark: "#387d39",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // background: 'linear-gradient(45deg, #17A673 100%, #FE6B8B 0%)',
    backgroundImage: "url(" + dummy + ")",
  },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "none",
  },
  avatar: {
    margin: theme.spacing(1),
    background: "#79c06e",
  },
  logo: {
    minWidth: "100px",
    marginTop: "10px",
    // marginBottom: "10px",
    width: "45%",
  },
  typography: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    fontWeight: "bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  notchedOutline: {
    borderWidth: "2px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "#17A673",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#17A673",
      },
    },
  },
}));

const StyledSubmmitButton = withStyles({
  root: {
    fontSize: 14,
    lineHeight: 2,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
})(Button);

const validationSchema = Yup.object({
  email: Yup.string()
    .required("メールアドレスを入力してください")
    .email("email", "無効なEmailです"),

  password: Yup.string()
    .min(8, "パスワードは8文字以上32文字以内で入力してください")
    .max(32, "パスワードは8文字以上32文字以内で入力してください")
    .required("パスワードを入力してください"),
});

function Copyright(props) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="http://www.iwabuchi-net.co.jp/">
        LEAFcafe
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const message =
  "当ショップは「健康管理アプリwithLEAF」をご利用の方のための会員制サイトです。\nご利用の際は、withLEAFアプリで使用されている\nメールアドレス及びパスワードでログインしてください。";
const caption =
  "ログイン情報をお忘れの場合は、\nwithLEAFアプリの「設定」<「ユーザー情報の変更」より\n再設定をお願いいたします。";

const style = {
    height: "150%",
    width: "150%",
    margin: "0 auto",
    marginTop: 150,
  };

export default function SignIn() {
  const classes = useStyles();
  const router = useHistory();
  const { useRequestLoginMutation } = useAuth();
  const requestLoginMutate = useRequestLoginMutation().mutate;

  const { values, touched, handleSubmit, handleChange, errors } = useFormik({
    initialValues: LOGIN_INITIAL_VALUES,
    validationSchema: LOGIN_VALIDATION_SCHEMA, // （2）
    onSubmit: onSubmit,
  });

  async function onSubmit() {
    try {
      await requestLoginMutate(values);
      router.push("/index");
    } catch (err) {
      console.log(err);
    }
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container component="main" 
        sx={{ height: "100vh" }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            // sx={{
            //   backgroundImage: 'url(https://source.unsplash.com/random)',
            //   backgroundRepeat: 'no-repeat',
            //   backgroundColor: (t) =>
            //     t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            //   backgroundSize: 'cover',
            //   backgroundPosition: 'center',
            // }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            // style={{ height: "100vh" }}
            >
            <Box className={classes.paper} 
            sx={{ my: 1, mx: 1 }}
              >
              {/* <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar> */}
              <img
                src={logo}
                alt="LEAFcafeロゴ"
                className={classes.logo}
              />
              <Box sx={{ height: 50 }}></Box>
              <Typography component="h1" variant="h5">
                ログイン
              </Typography>
              {/* 改行を入れる */}
              <div style={{ whiteSpace: "pre-line" }}>
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.typography}
                >
                  {message}
                </Typography>
                <Typography variant="body2" className={classes.typography}>
                  {caption}
                </Typography>
              </div>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  placeholder="メールアドレス"
                  name="email"
                  autoComplete="email"
                  color="secondary"
                  autoFocus
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  helperText={touched.email ? errors.email : ""}
                  className={classes.notchedOutline}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="パスワード"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  color="secondary"
                  value={values.password}
                  onChange={handleChange}
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password ? errors.password : ""}
                  className={classes.notchedOutline}
                />
                <StyledSubmmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  ログイン
                </StyledSubmmitButton>
                <div style={style}>
                <Toaster />
                </div>
                {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      パスワードを忘れた方はこちら
                    </Link>
                  </Grid>
                </Grid> */}
                <Copyright sx={{ mt: 5 , mb: 5}} />
              </form>
            </Box>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ButtonAppBar from '../modules/AppBar';
import FloatingBtn from '../modules/assets/FloatingButton';
import Footer from '../modules/Footer';

const useStyles = makeStyles((theme) => ({
    　grid: {
      margin: "auto",
    //   marginRight: theme.spacing(1),
    //   marginLeft: theme.spacing(1),
    },
  }));
  

export default function PrivacyPoricy() {
    const classes = useStyles();
    return (
      <div>
      <ButtonAppBar/>
        <Grid container justifyContent="center">
        <Grid item className={classes.grid} xs={10} >
          <Box 
            sx={{
                marginTop: 80,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
          >
            <Typography
                component="h1" variant="h4">
                プライバシーポリシー
            </Typography>
          </Box>
          <Box sx={{height: 30,}}></Box>
            <p><font size="2">LEAF Cafe（リーフカフェ）（以下「当ストア」といいます。）は、
            個人情報保護の重要性に鑑み、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）
            を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）を定めます。</font></p>
            <h3>1. 個人情報の定義</h3>
            <p><font size="2">本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された生存する個人に関する情報であって、
                当該情報に含まれる氏名、生年月日、住所、電話番号、メールアドレス、SNSアカウント情報、その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、
                それにより特定の個人を識別することができることとなるものを含みます。）、または個人識別符号が含まれる情報を意味するものとします。</font></p>
            <h3>2. 個人情報の取得方法</h3>
            <p><font size="2">（1）当ストアでは、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、SNSアカウント情報、銀行口座情報、クレジットカード情報、
            運転免許証等の公的身分証明情報などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされた、ユーザーの個人情報を含む取引記録や、決済に関する情報を当社の提携先
            （情報提供元、広告主、広告配信先などを含みます。以下｢提携先｣といいます。）などから取得することがあります。</font></p>
            <p><font size="2">（2）当ストアにおけるサービス（以下「本サービス」といいます。）では、ユーザーについて、利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索キーワード、利用日時、利用方法、利用環境
            （携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含む）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、
            当ストアや提携先のサービスを利用、またはページを閲覧する際に取得いたします。</font></p>
            <h3>3. 個人情報を取得・利用する目的</h3>
            <p><font size="2">当ストアが、お客様の個人情報を取得・利用する目的は以下の通りです。</font></p>
            <p><font size="2">（1）お客様ご自身の登録情報や利用状況の閲覧・修正を行っていただくために、氏名、住所、連絡先、支払方法などの登録情報、利用されたサービスや購入された商品、およびそれらの代金などに関する情報を表示する目的</font></p>
            <p><font size="2">（2）お客様へのお知らせや商品の送付、必要に応じてご連絡を行うため、氏名、住所、メールアドレス、SNSアカウント情報などの連絡先情報を利用する目的</font></p>
            <p><font size="2">（3）本人確認を行うために、氏名、生年月日、住所、電話番号、銀行口座情報、クレジットカード情報、運転免許証等の公的身分証明情報、配達証明付き郵便の到達結果などの情報を利用する目的</font></p>
            <p><font size="2">（4）商品代金のご請求のため、購入された商品名や数量、利用されたサービスの種類や期間、回数、ご請求金額、氏名、住所、電話番号、メールアドレス、SNSアカウント情報、銀行口座情報、クレジットカード番号などの支払いに関する情報などを利用する目的</font></p>
            <p><font size="2">（5）簡便にデータを入力できるようにするため、登録して頂いた情報を入力画面に表示させたり、お客様のご指示に基づいて他のサービスなど（提携先が提供するものも含みます。）に転送する目的</font></p>
            <p><font size="2">（6）代金の支払いを遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したお客様や、不正・不当な目的でサービスを利用しようとするお客様のご利用をお断りするために、利用態様、氏名、住所など個人を特定するための情報を利用する目的</font></p>
            <p><font size="2">（7）お客様からのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など、お客様に対してサービスを提供するにあたって必要となる情報や、お客様のサービス利用状況、連絡先情報などを利用する目的</font></p>
            <p><font size="2">（8）その他、前各号の利用目的に付随する目的</font></p>
            <h3>4. 個人情報利用目的の変更</h3>
            <p><font size="2">当ストアは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、
            変更した場合にはウェブサイトでお客様に通知または公表します。また、本サービスの掲載した時点で効力が生じるものとします。</font></p>
            <h3>5. 個人情報の安全管理</h3>
            <p><font size="2">当ストアは個人情報の正確性および安全性確保のために、個人情報の紛失、破壊、改ざんおよび漏洩などのリスクに対して、個人情報の安全管理が図られるようセキュリティに万全の対策を講じております。
            また当ストアの従業員に対し、必要かつ適切な監督を行います。当ストアは、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</font></p>
            <h3>6. 個人情報の第三者への提供</h3>
            <p><font size="2">（1）当ストアは、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。ただし、次の場合はこの限りではありません。</font></p>
            <p><font size="2">（2）お客様へのお知らせや商品の送付、必要に応じてご連絡を行うため、氏名や住所、メールアドレスなどの連絡先情報を利用する目的</font></p>
            <p><font size="2">（3）本人確認を行うために、氏名、生年月日、住所、電話番号、銀行口座番号、クレジットカード番号、運転免許証番号、配達証明付き郵便の到達結果などの情報を利用する目的</font></p>
            <p><font size="2">（4）商品代金のご請求のため、購入された商品名や数量、利用されたサービスの種類や期間、回数、ご請求金額、氏名、住所、銀行口座番号やクレジットカード番号などの支払いに関する情報などを利用する目的</font></p>
            <p><font size="2">（5）簡便にデータを入力できるようにするため、登録して頂いた情報を入力画面に表示させたり、お客様のご指示に基づいて他のサービスなど（提携先が提供するものも含む）に転送する目的</font></p>
            <p><font size="2">（6）代金の支払いを遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したお客様や、不正・不当な目的でサービスを利用しようとするお客様のご利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的</font></p>
            <p><font size="2">（7）お客様からのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など、お客様に対してサービスを提供するにあたって必要となる情報や、お客様のサービス利用状況、連絡先情報などを利用する目的</font></p>
            <p><font size="2">（8）上記の利用目的に付随する目的</font></p>
            <h3>4. 個人情報利用目的の変更</h3>
            <p><font size="2">当ストアは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはウェブサイトでお客様に通知又は公表します。また、本ウェブサイトに掲載した時点で効力が生じるものとします。</font></p>
            <h3>5. 個人情報の安全管理</h3>
            <p><font size="2">当ストアは個人情報の正確性及び安全性確保のために、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるようセキュリティに万全の対策を講じております。また当ストアの従業員に対し、必要かつ適切な監督を行います。当ストアは、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</font></p>
            <h3>6. 個人情報の第三者への提供</h3>
            <p><font size="2">（1）当ストアは、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。ただし、次の場合はこの限りではありません。</font></p>
            <p><font size="2">  ① 法令に基づく場合</font></p>
            <p><font size="2">  ② 人の生命、身体または財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</font></p>
            <p><font size="2">  ③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき</font></p>
            <p><font size="2">  ④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</font></p>
            <p><font size="2">  ⑤ あらかじめ、次の事項を告知あるいは公表している場合</font></p>
            <p><font size="2">　ア）利用目的に第三者への提供を含むこと</font></p>
            <p><font size="2">　イ）第三者に提供されるデータの項目</font></p>
            <p><font size="2">　ウ）第三者への提供の手段または方法</font></p>
            <p><font size="2">  エ）本人の求めに応じて個人情報の第三者への提供を停止すること</font></p>
            <p><font size="2">（2）前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。</font></p>
            <p><font size="2">①本サービスが利用目的の達成に必要な範囲内において、個人情報の全部または一部を委託する場合</font></p>
            <p><font size="2">②合併その他の事由による事業の承継に伴って、個人情報が提供される場合</font></p>
            <p><font size="2">③個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いている場合</font></p>
            <h3>7. 個人情報の適正な取得</h3>
            <p><font size="2">当ストアは、適正に個人情報を取得し、偽りその他不正の手段により取得しません。</font></p>
            <h3>8. 個人情報の開示</h3>
            <p><font size="2">当ストアは、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当ストアが開示の義務を負わない場合は、この限りではありません。</font></p>
            <h3>9. 個人情報の訂正または削除</h3>
            <p><font size="2">（1）当ストアが保有している個人情報が誤った情報である場合、個人情報保護法の定めに基づきその内容の訂正、追加または削除（以下「訂正等」といいます。）を請求することができます。</font></p>
            <p><font size="2">（2）お客様から前項の請求を受けて、その請求に応じる必要があると判断した場合、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その請求に応じる必要があると判断した場合、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。
                                  ただし、個人情報保護法その他の法令により、当ストアが訂正等の義務を負わない場合は、この限りではありません。</font></p>
            <h3>10. 個人情報の利用停止等</h3>
            <p><font size="2">当ストアは、お客様より、お客様の個人情報があらかじめ公表された利用目的の範囲を超えて取り扱われているという理由または偽りその他不正の手段により取得されたものであるという理由により、
                個人情報保護法の定めに基づきその利用の停止または消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、お客様ご本人からのご請求であることを確認の上で、
                個人情報の利用停止等を行い、その旨をお客様に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合、または利用停止を行うことが困難な場合は、これに代わるべき措置を講じます。
                また、必要な調査の結果、個人情報保護法その他の法令により、当ストアが利用停止等の義務を負わない場合は、この限りではありません。</font></p>
            <h3>1. Cookie（クッキー）その他の技術の利用</h3>
            <p><font size="2">当ストアのサービスは、Cookieおよびこれに類する技術を利用することがあります。これらの技術は、当ストアによる当ストアのサービスの利用状況等の把握に役立ち、サービス向上に資するものです。
                Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。ただし、Cookieを無効化すると、当ショップのサービスの一部の機能をご利用いただけなくなる場合があります。
                なお、この場合において、お客様に不利益が生じましても、当ストアでは一切その責任を負いません。</font></p>
            <h3>12. お問い合わせ</h3>
            <p><font size="2">開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、当ストアの「特定商取引法に基づく表記」内にある連絡先へご連絡いただくか、
                本サービスのウェブサイト内のお問い合わせフォームよりお問い合わせください。</font></p>
          <FloatingBtn /> 
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};



import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  Container,
  createTheme,
  Dialog,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useContact } from "../../../controllers/services/UseContact";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    textAlign: "center",
  },
  SubmitButton: {
    marginTop: theme.spacing(4),
  },
  CancelButton: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.primary.dark,
  },
  SndIcon: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StyledTableCell = withStyles({
  body: {
    fontSize: "small",
  },
})(TableCell);

const StyledButton = withStyles({
  root: {
    fontSize: 12,
    padding: "10px 14px",
    lineHeight: 2,
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
})(Button);

const createData = (title, detail) => {
  return { title, detail };
};

export default function ContactCheck(props) {
  const { usePostContact } = useContact();
  const usePostContactMutation = usePostContact().mutate;
  const classes = useStyles();
  const { values, isOpen, closeDialog, openNext } = props;
  const name = values.name;
  const res_mail_address = values.res_mail_address;
  const phone_number = values.phone_number;
  const company_name = values.company_name;
  const type = values.type;
  const body = values.body;
  const rows = [
    createData("氏名", name),
    createData("メールアドレス", res_mail_address),
    createData("電話番号", phone_number),
    createData("御社名", company_name),
    createData("お問い合わせ内容", type),
    createData("お問い合わせ内容の詳細", body),
  ];
  async function handleSubmit() {
    try {
      await usePostContactMutation(values);
      closeDialog();
      openNext();
    } catch (e) {
      console.debug(e);
    }
  }
  return (
    <ThemeProvider theme={theme}>
      {/* <ButtonAppBar/> */}
      <Dialog
        open={isOpen}
        className={classes.modal}
        maxWidth="xl"
        fullWidth="true"
      >
        <Container component="main" maxWidth="lg">
          <Grid container justifyContent="center">
            <Grid item className={classes.grid}>
              <Divider className={classes.divider} />
              <Typography component="h2" variant="h4">
                お問い合わせ内容のご確認
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body1" style={{ fontSize: 14 }}>
                こちらの内容で送信してよろしいですか？
              </Typography>
              <Box sx={{ height: 50 }}></Box>
              <div style={{ whiteSpace: "pre-line" }}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="medium"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.title}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            color="primary"
                          >
                            {row.title}
                          </StyledTableCell>
                          <StyledTableCell>{row.detail}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <StyledButton
                  onClick={handleSubmit}
                  className={classes.SubmitButton}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  送 信
                  <SendIcon className={classes.SndIcon} />
                </StyledButton>
                <StyledButton
                  className={classes.CancelButton}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={closeDialog}
                >
                  再編集
                </StyledButton>
                <Divider className={classes.divider} />
              </div>
              {/* </form> */}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </ThemeProvider>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, createTheme, Grid, ThemeProvider, Typography, withStyles } from '@material-ui/core';
import FloatingBtn from '../modules/assets/FloatingButton';
import ButtonAppBar from '../modules/AppBar';
import Footer from '../modules/Footer';
import GMOimg from '../img/parts/ps_banner_3104.gif'
import { Link } from 'react-router-dom';

const theme = createTheme({
  button: {
    fontFamily: [
      'ヒラギノ角ゴ ProN',
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
    palette: {
      primary: {
        light: '#e6eae6',
        main: '#d3d3d8',
        dark: '#c9caca',
        contrastText: '#626063',
      },
      secondary: {
        light: '#ff7f50',
        main: '#ff4500',
        dark: '#cc3600',
        contrastText: '#ffffff',
      },
    },
  });


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  GMOimg: {
    alignContent: 'left',
    marginTop: theme.spacing(2),
  },
  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledTableCell = withStyles({
  body: {
    fontSize: "small",
  },
})(TableCell);


const createData　= (title, detail) => {
  return { title, detail };
}

const rows = [
  createData('販売業者', '岩渕薬品株式会社'),
  createData('運営統括責任者', '代表取締役専務　岩渕裕樹'),
  createData('ストア名', 'LEAF Cafe リーフカフェ'),
  createData('所在地', '〒284-0033 千葉県四街道市鷹の台1-5'),
  createData('電話番号', '043-236-7707\n受付時間9：00~17：00（土日祝日、年末年始を除く。）'),
  createData('メールアドレス', 'iw-shop@iwabuchi-net.co.jp'),
  createData('販売価格', '商品ページに表示された金額（税込）'),
  createData('商品代金以外の必要料金', '送料 660円（税込）　\n※ただし4,980円（税込）以上の購入で送料無料　\n※沖縄への配送は、購入金額にかかわらず1,100円（税込）\n\n代引手数料 330円（税込）\n\n銀行振込手数料 金融機関により異なります。'),
  createData('お支払方法およびお支払の時期', `■ クレジットカード\nご利用のクレジットカード会社ごとに異なります。\n\n■ 代金引換\n商品受取時にお支払いください\n\n■ コンビニ後払い（GMO後払い）\nGMO後払いとはお客様の手元に商品が到着した後に代金をお支払い頂く決済方法です。商品出荷後、商品とは別に払込票を郵送いたしますので、お近くのコンビニエンスストア・銀行・LINE Payにてお支払いください。\n※銀行振込の場合の手数料はお客様負担となります。また、振込先の口座番号は お取引ごとに毎回異なりますので、請求書に記載された口座番号をご確認ください。\n・お支払い期限 請求書発行から14日以内にお支払いください。お支払い期限は請求書にも記載しております。\n\n GMO後払いについての注意は、画面最下部をご確認ください。`),
  createData('商品の引渡時期', 'ご注文から３～４営業日以内'),
  createData('商品の引渡方法', '指定のお届け先へ配送。'),
  createData('返品·交換·キャンセルについて', '返品・交換・キャンセルをご希望される場合は、あらかじめお問い合わせフォームよりご連絡下さい。 \n以下の場合の返品・交換・キャンセルは、お受けいたしかねます。　\n\n・お届け後８日以上経過した商品　\n・開封後の商品　\n・ご使用になられた商品\n・セール商品\n・受注生産品\n・お客様が破損・汚損された商品\n・お客様都合による返品・交換（イメージ違い・サイズ違いなど）\n・ご注文者様とお届け先が異なる場合（不良品を除く）\n・そのほか、販売時に返品対象外と記載のある商品'),
  createData('返品送料', 'お客様負担。ただし、不良及による返品にかかる送料は、弊社が負担させていただきます。'),
  createData('返品期限', '商品のお受取りより８日間'),
];

const GMOtitle = `【GMO後払いについてのご注意】`;
const GMO1 = `\n\n●GMO後払いのご注意\n・ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。\n・ご利用にあたり審査がございます。審査結果によっては「GMO後払い」をご利用いただけない場合がございますので、その場合には別のお支払方法へ変更をお願いします。\n・「GMO後払い」はGMOペイメントサービス株式会社が提供するサービスです。当社はGMOペイメントサービス株式会社に対しサービスの範囲内で個人情報を提供し、代金債権を譲渡します。\n・ご利用限度額はGMO後払い累計で、55,000円（税込）です。\n・商品のお届け先が「運送会社留め」「郵便局留め」、また「学校」「病院」「ホテル」など一時滞在となる場合は、GMO後払いをご利用いただけません。商品の転送サービスもご利用いただけません。\n※お届け先が勤務先の場合はご利用いただけますのでご住所の後に「勤務先」とご入力ください。\n※ギフトのお届け先が一時滞在となるご住所の場合にはご利用になれます。`;

export default function TradelowTable() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <ButtonAppBar/>     
    <Grid container justifyContent="center">
      <Grid item className={classes.grid} > 
        <Box 
            sx={{
                marginTop: 80,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        　　>
          <Typography
              component="h1" variant="h4">
              特定商取引法に基づく表示
          </Typography>
        </Box>
        <Box sx={{height: 30,}}></Box>
        {/* 改行を入れる */}
        <div style={{whiteSpace: 'pre-line'}}>
          <TableContainer component={Paper} >
            <Table className={classes.table} size="medium" aria-label="a dense table">       
              <TableBody>
               {rows.map((row) => (
                <TableRow key={row.title}>
                  <StyledTableCell component="th" scope="row" color='primary' >
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell >
                    {row.detail}
                  </StyledTableCell>
                </TableRow>
               ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{whiteSpace: 'pre-line'}}>
          <Paper className={classes.paper}>
            {GMOtitle}
            {GMO1}
            <a href="http://www.gmo-ps.com/customer_2/"  target="_blank">
              <img className={classes.GMOimg} src={GMOimg} alt="GMO後払い" />
            </a>
          </Paper>
        </div>
        <FloatingBtn /> 
      </Grid>
    </Grid>
    <Footer />
    </ThemeProvider>
  );
}

import * as React from "react";
import {
  Box,
  createTheme,
  CssBaseline,
  Grid,
  Link,
  makeStyles,
  Paper,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import Image from "../img/logo/LEAFcafe_logo.jpg";
import dummy from "../img/dummy.jpg";


const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#89c997",
      main: "#79c06e",
      dark: "#387d39",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // background: 'linear-gradient(45deg, #17A673 100%, #FE6B8B 0%)',
    backgroundImage: "url(" + dummy + ")",
  },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  typography: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    fontWeight: "bold",
  },
  notchedOutline: {
    borderWidth: "2px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "#17A673",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#17A673",
      },
    },
  },
}));


function Copyright(props) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        LEAFcafe
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const message =
  "\n現在、開店準備中です。\nオープンまで今しばらくお待ちください。\n\n";

export default function Preparation() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            style={{ height: "100vh" }}
          >
            <Box className={classes.paper} sx={{ my: 8, mx: 4 }}>
              <img src={Image} width="30%" height="30%" alt="" />
              <Box sx={{ height: 30 }}></Box>
              <Typography component="h1" variant="h5">
                　サイト準備中...
              </Typography>
              {/* 改行を入れる */}
              <div style={{ whiteSpace: "pre-line" }}>
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.typography}
                >
                  　{message}
                </Typography>
                {/* <Typography variant="body2" className={classes.typography}>
                  　{caption}
                </Typography> */}
              </div>

                <Copyright sx={{ mt: 5 }} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

import { useQueryClient, useMutation } from "react-query";
import { commonHttpClient } from "./commonHttpClient";

export const useContact = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const postContact = async ({
    name,
    phone_number,
    res_mail_address,
    type,
    body,
    company_name,
  }) => {
    await http.post("/contact", {
      name,
      phone_number,
      res_mail_address,
      type,
      body,
      company_name,
    });
  };
  const usePostContact = () =>
    useMutation(postContact, {
      onSuccess: async () => {
        setTimeout(() => {}, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          console.debug(e);
        }
      },
    });

  return { usePostContact };
};

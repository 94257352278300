/* eslint-disable */
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { gql } from "babel-plugin-graphql-js-client-transform";
import Products from "./components/pages/Products";
import Index from "./components/pages/index";
import PrivacyPoricy from "./components/pages/PrivacyPoricy";
import TradelowTable from "./components/pages/Tradelow";
import AgreementTable from "./components/pages/Agreement";
import UsersguidTable from "./components/pages/UsersGuid";
import ContactForm from "./components/pages/Contact/ContactForm";
import ProductPage from "./components/pages/ProductPage";
import { createTheme, ThemeProvider } from "@material-ui/core";
import ContactCheck from "./components/pages/Contact/ContactCheck";
import ContactThanks from "./components/pages/Contact/ContactThanks";
import MyAccount from "./components/pages/MyAccount/MyAccount";
import AccountChange from "./components/pages/MyAccount/AccountChange";
import AccountChangeDone from "./components/pages/MyAccount/AccountChangeDone";
import AccountCheck from "./components/pages/MyAccount/AccountCheck";
import MyOrders from "./components/pages/MyOrder/MyOrders";
import MyOrder from "./components/pages/MyOrder/Myorder";
import SignIn from "./components/pages/SignIn";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import CommonContext from "./utils/CommonContext";
import Preparation from "./components/pages/Preparation";
import ScrollToTop from "./utils/ScrollToTop";
import Page from "./components/pages/Page";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "メイリオ",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#DDFFBC",
      main: "91C788",
      dark: "#52734D",
      contrastText: "#ffffff",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
    },
  },
});

function App() {

    return (
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <CommonContext>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="App">
            <ScrollToTop>
            <Switch>
              {/* <Route exact path="/preparation" component={Preparation} /> */}
              {/* <Route exact path="/login" component={Preparation} /> */}
              {/* <Route exact path="/index" component={Preparation} /> */}
              <PublicRoute exact path="/login" component={SignIn} />
              <PrivateRoute exact path="/index" component={Index} />
              <PrivateRoute exact path="/products" component={Products} />
              <PrivateRoute exact path="/product" component={ProductPage} />
              <PrivateRoute exact path="/privacy" component={PrivacyPoricy} />
              <PrivateRoute exact path="/tradelow" component={TradelowTable} />
              <PrivateRoute exact path="/agreement" component={AgreementTable} />
              <PrivateRoute exact path="/usersguid" component={UsersguidTable} />
              <PrivateRoute exact path="/page" component={Page} />
              <PrivateRoute exact path="/contact" component={ContactForm} />
              <PrivateRoute exact path="/contact/check" component={ContactCheck} />
              <PrivateRoute exact path="/contact/thanks" component={ContactThanks} />
              <PrivateRoute exact path="/myaccount" component={MyAccount} />
              <Route exact path="/myaccount/check" component={AccountCheck} />
              <Route exact path="/myaccount/change" component={AccountChange} />
              <Route
                exact
                path="/myaccount/change/success"
                component={AccountChangeDone}
              />
              <PrivateRoute exact path="/myaccount/myorders" component={MyOrders} />
              <PrivateRoute exact path="/myaccount/myorder" component={MyOrder} />
              <PublicRoute
                path="/"
                render={() => {
                  return <Redirect to="/login" />;
                }}
              />
            </Switch>
            </ScrollToTop>
          </div>
          {/* {location.pathname !== "/login" ? <Footer /> : null} */}
        </Router>
      </ThemeProvider>
      </CommonContext>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default App;

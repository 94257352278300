import { createTheme, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "メイリオ",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#89B929",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',

  },
  link:{
    textDecoration: 'none',
  },
  card: {
    position: 'absolute',
    paddingBottom: "0px",
    alignContent: 'center',
    backgroundColor: '#dd0000',
    // opacity: 0.8,
    top: '90%',
    left: '0%',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
    zIndex: theme.zIndex.drawer + 1,
  },
  cardContet: {
    '&:last-child': {
      paddingBottom: 4,
    },
    padding: 4,
  },
  Typography: {
    margin: "4px",
  }
}));

function Product(props) {
  const classes = useStyles();
  const productPrice = props.product.node.variants.edges[0].node.price.amount;
  const productCAPrice = props.product.node.variants.edges[0].node.compareAtPrice.amount;

    return (
      <div className="Product">
        <div>
        <Link
          className={classes.link}
          to={{
            pathname: "/product",
            search: `?handle=${props.product.node.handle}`,
          }}>
            <div className={classes.root}>
            <img  src={props.product.node.images.edges[0].node.url} alt={` product shot`} />
            {
             (() => {
              if (productPrice != productCAPrice ) {
                const disount = ( 1 -  productPrice / productCAPrice ) * 100;
                return(
                <Paper
                  className={classes.card}
                  variant="outlined"
                  square>
                    <Typography variant="body2" className={classes.Typography}>
                      {Math.floor(disount)}％OFF
                    </Typography>
                </Paper> 
              )}
             })()}
             </div>
            <h5 
              className="Product__title"
              style={{ color: 'black' }}>
                {props.product.node.title}
                </h5>
            </Link>
            </div>
        <h4 className="Product__price" >{Number(props.product.node.variants.edges[0].node.price.amount).toLocaleString()}円 (税込)</h4>
        {/* <Link
          to={{
            pathname: "/product",
            search: `?pid=${this.props.product.node.id}`,
          }}>
        <button className="Product__buy button" 
           >詳しくはこちら</button>
        </Link> */}
      </div>
    );
  }

  export default Product;
  

  
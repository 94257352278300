import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ButtonAppBar from '../modules/AppBar';
import Footer from '../modules/Footer';

const useStyles = makeStyles((theme) => ({
    　grid: {
        margin: "auto",
        // marginRight: theme.spacing(2),
        // marginLeft: theme.spacing(2),
      },
  }));

  const msg = "この利用規約（以下、「本規約」といいます。）は、岩渕薬品株式会社（以下「当社」といいます。）がこのウェブサイト上で提供するオンラインショップLEAF Cafe（以下「本サービス」といいます。）の利用条件を定めるものです。\n登録ユーザーの皆さま（以下「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。";
  const msg1 = "1.本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。\n2.当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下「個別規定」といいます。）をすることがあります。これら個別規定は、その名称のいかんに関わらず、本規約の一部を構成するものとします。\n3.本規約の定めが前項の個別規定の定めと矛盾する場合には、個別規定において特段の定めなき限り、個別規定の定めが優先されるものとします。";
  const msg2 = "1.本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれに対する承認を登録希望者に通知することによって、ユーザーとして利用登録が完了するものとします。\n2.当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。\n(1)利用登録の申請に際して虚偽の事項を届出た場合\n(2)本規約に違反したことがある者からの申請である場合\n(3)その他、当社が利用登録を相当でないと判断した場合";
  const msg3 = "1.ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。\n2.ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組合わせが登録情報と一致してログインされた場合（ヘルスケアアプリケーション「LEAF」のアプリ上から自動ログインした場合も含みます。）には、そのユーザーIDを登録しているユーザー自身による利用とみなします。\n3.ユーザーIDおよびパスワードが第三者に使用されたことによって生じた損害は、当社に故意または重大な過失がある場合を除き、当社は一切の責任を負わないものとします。";
  const msg4 = "1.本サービスにおいては、ユーザーが当社に対して購入の申込みをし、これに対して当社が当該申込みを承諾した旨の通知をした時点で売買契約が成立するものとします。なお、当該商品の所有権は、当社が商品を配送業者に引渡した時点で、ユーザーに移転するものとします。\n2.当社は、ユーザーが以下のいずれかの事由に該当する場合には、当該ユーザーに事前に通知することなく、前項の売買契約を解除することができるものとします。\n(1)ユーザーが本規約に違反した場合\n(2)届先不明や長期の不在のため商品の配送が完了しない場合\n(3)その他当社とユーザーの信頼関係が損なわれたと認める場合\n3.本サービスに関する決済方法、配送方法、購入の申込みのキャンセル方法、または返品方法等については、別途当社が定める方法によります。\n4.本サービスによる商品の配送先は、日本国内に限ります。";
  const msg5 = "本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます。）の著作権またはその他の知的所有権は、当社およびコンテンツ提供者などの正当な権利者に帰属し、ユーザーは、これらを無断で複製、転載、改変、その他の二次利用をすることはできません。";
  const msg6 = "ユーザーは、本サービスの利用にあたり、以下の行為をしてはならないものとします。\n(1)法令または公序良俗に違反する行為\n(2)犯罪行為に関連する行為\n(3)本サービスに含まれる著作権、商標権その他の知的財産権を侵害する行為\n(4)当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為\n(5)本サービスによって得られた情報を商業的に利用する行為\n(6)当社のサービスの運営を妨害するおそれのある行為\n(7)不正アクセスをし、またはこれを試みる行為\n(8)他のユーザーに関する個人情報等を収集または蓄積する行為\n(9)他のユーザーに成りすます行為\n(10)当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為\n(11)その他、当社が不適切と判断する行為";
  const msg7 = "1.当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。\n(1)本サービスにかかるコンピュータシステムの保守点検または更新を行う場合\n(2)地震、落雷、火災、停電、疾病蔓延、その他天災または第三者による妨害行為などの不可抗力により、本サービスの提供が困難となった場合\n(3)コンピューターまたは通信回線等が事故等により停止した場合\n(4)その他、当社が本サービスの提供が困難と判断した場合\n2.当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。" ;
  const msg8 = "1.当社は、以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。\n(1)本規約のいずれかの条項に違反した場合\n(2)登録事項に虚偽の事実があることが判明した場合\n(3)決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合\n(4)料金等の支払債務の不履行があった場合\n(5)当社からの連絡に対し、一定期間返答がない場合\n(6)本サービスについて、最終の利用から一定期間利用がない場合\n(7)その他、当社が本サービスの利用を適当でないと判断した場合\n2.当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。";
  const msg9 = "ユーザーは、所定の退会手続により、本サービスから退会できるものとします。";
  const msg10 = "1.当社は、本サービスに事実上または法律上の不適合（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みますが、これらに限られません。）が無いことを保証するものではありません。\n2.当社は、本サービスによってユーザーに生じたあらゆる損害について、一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されませんが、この場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。\n3.当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。";
  const msg11 = "当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。";
  const msg12 = "当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。";
  const msg13 = "当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。";
  const msg14 = "ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更の届出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは発信時にユーザーへ到達したものとみなします。";
  const msg15 = "ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。";
  const msg16 = "1.本規約の解釈にあたっては、日本法を準拠法とします。なお、本サービスに関しては、国際物品売買契約に関する国際連合条約の適用を排除するものとします。\n2.本サービスに関して紛争が生じた場合には、千葉地方裁判所を第１審の専属的合意管轄の裁判所とします。";
  const msg17 = "利用規約に関するご質問は、特定商取引法に基づいて記載されている連絡先までお寄せください。";

export default function AgreementTable() {
    const classes = useStyles();
    return (
      <div>
      <ButtonAppBar/>
        <div style={{whiteSpace: 'pre-line'}}>
          <Grid container justifyContent="center">
            <Grid item className={classes.grid} xs={9} >
            <Box 
                sx={{
                    marginTop: 80,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            　　>
              <Typography
                  component="h1" variant="h4">
                  ご利用規約
              </Typography>
            </Box>
            <Box sx={{height: 30,}}></Box>
              <p><font size="2">{msg}</font></p>
              <h3>第1条（適用）</h3>
              <p><font size="2">{msg1}</font></p>
              <h3>第2条（利用登録）</h3>
              <p><font size="2">{msg2}</font></p>
              <h3>第3条（ユーザーIDおよびパスワードの管理）</h3>
              <p><font size="2">{msg3}</font></p>
              <h3>第4条（売買契約）</h3>
              <p><font size="2">{msg4}</font></p>
              <h3>第5条（知的財産権）</h3>
              <p><font size="2">{msg5}</font></p>
              <h3>第6条（禁止事項）</h3>
              <p><font size="2">{msg6}</font></p>
              <h3>第7条（本サービスの提供の停止等）</h3>
              <p><font size="2">{msg7}</font></p>
              <h3> 第8条（利用制限および登録抹消）</h3>
              <p><font size="2">{msg8}</font></p>
              <h3>第9条（退会）</h3>
              <p><font size="2">{msg9}</font></p>
              <h3>第10条（保証の否認および免責事項）</h3>
              <p><font size="2">{msg10}</font></p>
              <h3>第11条（サービス内容の変更等）</h3>
              <p><font size="2">{msg11}</font></p>
              <h3>第12条（利用規約の変更）</h3>
              <p><font size="2">{msg12}</font></p>
              <h3>第13条（個人情報の取扱い）</h3>
              <p><font size="2">{msg13}</font></p>
              <h3>第14条（通知または連絡）</h3>
              <p><font size="2">{msg14}</font></p>
              <h3>第15条（権利義務の譲渡の禁止）</h3>
              <p><font size="2">{msg15}</font></p>
              <h3>第16条（準拠法・裁判管轄）</h3>
              <p><font size="2">{msg16}</font></p>
              <h3>第17条（連絡先情報）</h3>
              <p><font size="2">{msg17}</font></p>
              <p></p>
              <p><font size="2">以上</font></p>
              <p></p>
              <p> </p>
            </Grid>
          </Grid>
        </div>
        <Footer />
        </div>
    );
  };



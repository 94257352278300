import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hashlink';
import {
  createTheme,
  makeStyles,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { ButtonGroup, Divider, Hidden, Typography } from "@material-ui/core";
import TemporaryDrawer from "./assets/Drawer";
import Image from "../img/logo/LEAFcafe_logo.jpg";
import logo from "../img/logo/LEAFcafe_logo_353.png";
import Cart from "./Cart";
import { useUser } from "../../controllers/services/UseUser";
import { IsCartOpenContext } from "../../utils/CommonContext";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#89B929",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "primary",
  },
  logo: {
    minWidth: "100px",
    marginTop: "auto",
    width: "25%",
    height: "25%",
    display: "block",
  },
  logo_PC: {
    minWidth: "100px",
    marginTop: "10px",
    marginBottom: "10px",
    width: "15%",
    height: "15%",
    display: "block",
  },
  divider: {
    width: "100%",
    height: 1,
  },
  toolbar: {
    backgroundColor: "#ffffff",
    position: "relative",
    zIndex: theme.zIndex.drawer + 1,
    minHeight: "50px",
    // paddingRight: 24,
  },
  toolbar2: {
    backgroundColor: "#ffffff",
    display: "block",
    position: "relative",
    zIndex: theme.zIndex.drawer + 1,
    // paddingRight: 24,
  },
  toolbar3: {
    backgroundColor: "#ffffff",
    display: 'flex',
    position: "relative",
    justifyContent: 'center', 
    zIndex: theme.zIndex.drawer + 1,
    // paddingRight: 24,
  },
  offset: {
    ...theme.mixins.toolbar,
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    textAlign: "center",
    fontSize: 12,
  },

  toolbarMargin: theme.mixins.toolbar,
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "auto",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const MenuButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}))(Button);

// const StyledDivider = withStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.primary.contrastText
//   },
// }))(Divider);

const StyledCartIcon = withStyles((theme) => ({
  root: {
    fontSize: 24,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:active": {
      color: theme.palette.secondary.main,
    },
  },
}))(ShoppingCartIcon);

const StyledAccountIcon = withStyles((theme) => ({
  root: {
    fontSize: 24,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:active": {
      color: theme.palette.secondary.main,
    },
  },
}))(AccountCircleIcon);


export default function ButtonAppBar() {
  const classes = useStyles();

  const { isCartOpen, setIsCartOpen } = useContext(IsCartOpenContext);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const { useGetUserPoints } = useUser();
  const {
    data: Points,
    isLoading: pointsIsLoading,
    isError: pointIsError,
  } = useGetUserPoints();

  if (pointsIsLoading) {
    return <div></div>;
  }
  if (pointIsError) {
    return <div></div>;
  }
  if (Points) {
    const points = Points.possession;
    const pointsDate = Points.point_expiration_date ? Points.point_expiration_date : "";

    // .toLocaleString({ timeZone: 'Asia/Tokyo' })
    // 商品一覧のカテゴリー展開用
    const handleClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
    };

    // ご利用案内のカテゴリー展開用
    const handleClick3 = (event) => {
      setAnchorEl3(event.currentTarget);
    };

    // 商品一覧のカテゴリー展開用
    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    // ご利用案内のカテゴリー展開用
    const handleClose3 = () => {
      setAnchorEl3(null);
    };

    const pointInfo = `利用可能ポイント\n${points}pt`;

    return (
      <div>
        <ThemeProvider theme={theme}>
        <div style={{ flexDirection:"column" }}>
        <AppBar position="fixed">
            <Hidden xsDown>
            <Toolbar className={classes.toolbar2}>
              <Link to="/index">
                <img
                  src={logo}
                  className={classes.logo_PC}
                  alt="LEAFcafeロゴ"
                />
              </Link>
              {/* <StyledDivider className={classes.divider} /> */}
            <hr />
            </Toolbar>
            </Hidden>
          {/* </AppBar>
          <AppBar position="fixed"> */}
            <Toolbar className={classes.toolbar}>
              <Hidden smUp>
                <TemporaryDrawer points={points} />
              </Hidden>

              <IconButton >
              <Link to="/index" className="Link">
                <HomeIcon fontSize="large" />
              </Link>
              </IconButton>
              <Hidden xsDown>
                <ButtonGroup
                  variant="text"
                  color="primary"
                  aria-label="text primary button group"
                >
                  <MenuButton
                    // component={Link} to="/products"
                    aria-owns={anchorEl2 ? "simple-menu" : undefined}
                    aria-controls="customized-menu2"
                    aria-haspopup="true"
                    color="primary"
                    onClick={handleClick2}
                    // onMouseEnter={handleClick2}
                  >
                    商品カテゴリー
                  </MenuButton>
                  <MenuButton
                    // component={Link} to="/usersguid"
                    aria-owns={anchorEl3 ? "simple-menu" : undefined}
                    aria-controls="customized-menu3"
                    aria-haspopup="true"
                    color="primary"
                    onClick={handleClick3}
                    // onMouseOver={handleClick3}
                  >
                    ご利用案内
                  </MenuButton>
                  <Link to="/contact" style={{ textDecorationLine: "none" }}>
                    <MenuButton>お問い合わせ</MenuButton>
                  </Link>
                </ButtonGroup>
              </Hidden>
              {/* 商品一覧用メニュー */}
              <StyledMenu
                id="customized-menu2"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
                MenuListProps={{ onMouseLeave: handleClose2 }}
              >
                <Link
                  to={{
                    pathname: "/products",
                    search: `cid=0`,
                  }}
                  className="Link"
                >
                  <MenuItem>商品カテゴリー一覧 </MenuItem>
                </Link>
                <Link
                  to={{
                    pathname: "/products",
                    search: `?cid=1`,
                  }}
                  className="Link"
                >
                  <MenuItem> ・食事の質を上げたい方に</MenuItem>
                </Link>
                <Link
                  to={{
                    pathname: "/products",
                    search: `?cid=2`,
                  }}
                  className="Link"
                >
                  <MenuItem> ・健康に過ごしたい方に</MenuItem>
                </Link>
                <Link
                  to={{
                    pathname: "/products",
                    search: `?cid=3`,
                  }}
                  className="Link"
                >
                  <MenuItem> ・千葉県の商品</MenuItem>
                </Link>
                <Link
                  to={{
                    pathname: "/products",
                    search: `?cid=4`,
                  }}
                  className="Link"
                >
                  <MenuItem> ・送料無料</MenuItem>
                </Link>

                <Link
                  to={{
                    pathname: "/products",
                    search: `?cid=0`,
                  }}
                  className="Link"
                >
                  <MenuItem> ・全商品</MenuItem>
                </Link>
              </StyledMenu>
              {/* ご利用案内用メニュー */}
              <StyledMenu
                id="customized-menu3"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
                MenuListProps={{ onMouseLeave: handleClose3 }}
              >
                <Link to="/usersguid" className="Link">
                  <MenuItem>ご利用案内</MenuItem>
                </Link>
                <HashLink to="/usersguid/#shiharai" className="Link">
                  <MenuItem>・お支払いについて </MenuItem>
                </HashLink>
                <HashLink to="/usersguid/#haiso" className="Link">
                  <MenuItem>・配送･送料について</MenuItem>
                </HashLink>
                <HashLink to="/usersguid/#henpin" className="Link">
                  <MenuItem>・返品交換について</MenuItem>
                </HashLink>
                <HashLink to="/usersguid/#cansel" className="Link">
                  <MenuItem>・キャンセルについて</MenuItem>
                </HashLink>
                <HashLink to="/usersguid/#privacy" className="Link">
                  <MenuItem>・個人情報について</MenuItem>
                </HashLink>
              </StyledMenu>
              <Hidden smUp>
              <div className={classes.logo_wrap}>
                <Link to="/index">
                  <img
                    src={Image}
                    alt="LEAFcafeロゴ"
                    className={classes.logo}
                    // style={{alignItems: "center"}}
                  />
                </Link>
                </div>
                </Hidden>
              {/* アイコンを右寄せで配置する */}
              <div style={{ flexGrow: 1 }}></div>
              <Hidden xsDown>
                {/* 改行を入れる */}
                <div style={{ whiteSpace: "pre-line" }}>
                  <Typography className={classes.text}>{pointInfo}</Typography>
                </div>
              </Hidden>
              <IconButton edge="center" >
                <Link to="/myaccount">
                  <StyledAccountIcon />
                </Link>
              </IconButton>
              <IconButton>
                <StyledCartIcon onClick={()=>setIsCartOpen(true)}/>
              </IconButton>
              <Cart
                points={points}
                date={pointsDate}
              />
            </Toolbar>
            {/* 休業のお知らせを入れる時などに使用する */}
            {/* <Toolbar className={classes.toolbar3}>
            <Typography className={classes.text}>
              8/11〜8/20は夏季休業のため出荷停止となります
            </Typography>
            </Toolbar> */}
            </ AppBar>
          </div>
          <div className={classes.toolbarMargin} />
          <div className={classes.toolbarMargin} />
        </ThemeProvider>
      </div>
    );
  }
}

import { commonHttpClient } from './commonHttpClient';

const { http } = commonHttpClient();

export const getUserDetailInfo = async () => {
  const { data } = await http.get(`/users/me`);
  return data;
};

export const getProductDetailInfo = async (handle) => {
  const { data } = await http.get(`/products/product?handle=${handle}`);
  return data;
};

export const getPageInfo = async (handle) => { 
  const { data } = await http.get(`/page/page?handle=${handle}`);
  return data;
};
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
  Dialog,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    textAlign: "center",
  },
  typography: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(4),
    fontSize: 14,
  },

  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const title = "お問い合わせの送信が完了しました";
const subtitle =
  "フォームが送信されました。\nお問い合わせいただき、ありがとうございました。";
const message1 =
  "ご入力いただいたメールアドレス宛に受付の自動返信メールをお送りしております。\n受信箱に見当たらない場合は、迷惑メールフォルダをご確認ください。\nメールが届かない原因として、入力いただいたメールアドレスが間違っていたり、お客様の「受信拒否設定」などが考えられます。";
const message2 =
  "いただいたお問い合わせには、３営業日を目安に担当より回答させていただいております。\nそれ以降も回答がない場合にはお問い合わせが届いていない可能性がありますので、お手数ですがお電話にてお問い合わせください。";

export default function ContactThanks(props) {
  const classes = useStyles();
  const { isDone, closeDialog2 } = props;
  const history = useHistory();
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isDone}
        className={classes.modal}
        maxWidth="xl"
        fullWidth="true"
      >
        <Grid container justifyContent="center">
          <Grid item className={classes.grid}>
            {/* 改行を入れる */}
            <div style={{ whiteSpace: "pre-line" }}>
              <Box
                sx={{
                  marginTop: 40,
                  marginBottom: 40,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={classes.typography}
                  component="h1"
                  variant="h4"
                >
                  {title}
                </Typography>
                <Typography
                  className={classes.typography}
                  component="h2"
                  variant="h6"
                >
                  {subtitle}
                </Typography>
                <Typography className={classes.typography} variant="body1">
                  {message1}
                </Typography>
                <Typography className={classes.typography} variant="body1">
                  {message2}
                </Typography>
                <Button
                  onClick={() => {
                    closeDialog2;
                    history.push("/index");
                  }}
                  className={classes.button}
                >
                  TOPへ戻る
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

import { commonHttpClient } from "./commonHttpClient";
import { useQueryClient, useQuery } from "react-query";

export const useOrders = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const getOrders = async (email) => {
    let orders;
    await http
      .get("/orders", {
        params: {
          // ここにクエリパラメータを指定する
          email: email,
        },
      })
      .then((response) => {
        orders = response;
      })
      .catch((err) => {
        console.debug("注文履歴取得失敗:", err.response);
      });
    return orders.data;
  };
  const getOrderDetail = async (gid) => {
    let orderDetail;
    await http
      .get("/orders/detail", {
        params: {
          gid: gid,
        },
      })
      .then((response) => {
        orderDetail = response;
      })
      .catch((err) => {
        console.debug("注文詳細取得失敗:", err.response);
      });
    return orderDetail.data;
  };

  return {
    getOrders,
    getOrderDetail,
  };
};

import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import LoadingBubbleOrganisms from '../components/organisms/LoadingBubbleOrganisms';
import { useAuth } from '../controllers/services/UseAuth';


const Public = ( props ) => {
  const { useGetAuth } = useAuth();
  const { isLoading, data, isError, error } = useGetAuth();

  if (isLoading) {
    return (
      // <AuthTemplate>
      //   <></>
      // </AuthTemplate>

        <LoadingBubbleOrganisms />

    );
  }

  if (data !== undefined && Object.keys(data).length) {
    return <Redirect to={'/index'} />;
  }

  // if (Date.now() < Number(localStorage.getItem('exp')) * 1000) {
  //   localStorage.removeItem('token');
  //   return <Redirect to={'/dashboard'} />;
  // }

  return <Route {...props} />;
};
export default Public;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  createTheme,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUser } from "../../../controllers/services/UseUser";
import { useMarketUser } from "../../../controllers/services/UseMarkUser";
import LoadingBubbleOrganisms from "../../organisms/LoadingBubbleOrganisms";
import { useHistory, Link } from "react-router-dom";
import ButtonAppBar from "../../modules/AppBar";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(10),
    // textAlign: 'center',
  },
  sarchButton: {
    margin: theme.spacing(2),
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
  form: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
}));

const StyledTextField = withStyles({
  root: {
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
})(TextField);

const StyledAddressSarchButton = withStyles({
  root: {
    fontSize: 12,
    padding: "10px 10px",
    lineHeight: 2,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    backgroundColor: theme.palette.primary.main,
  },
})(Button);

const StyledSubmmitButton = withStyles({
  root: {
    fontSize: 14,
    padding: "10px 14px",
    lineHeight: 2,
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
})(Button);

const StyledReturnButton = withStyles({
  root: {
    fontSize: 14,
    padding: "10px 14px",
    lineHeight: 2,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    backgroundColor: theme.palette.primary.main,
  },
})(Button);

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("氏を入力してください"),
  lastName: Yup.string().required("名を入力してください"),
  phone: Yup.string()
    .min(10, "10桁以上で入力してください")
    .max(13, "13桁以内で入力してください")
    .required("電話番号を入力してください")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "正しい電話番号を入力してください"
    ),
  zip: Yup.string()
    .min(7, "郵便番号はハイフンなしで７桁で入力してください")
    .max(7, "郵便番号はハイフンなしで７桁で入力してください"),
  province: Yup.string().required("都道府県を入力してください")
    .matches("","都道府県を入力してください"),
  address1: Yup.string().required("住所を入力してください"),
});

const info =
  "お客様の現在のご登録は下記のようになっております。\nご変更がある場合には、下記をご変更のうえ、「変更内容の送信」ボタンを押してください。";
const caption =
  "メールアドレス及びパスワードはこちらのサイトではご変更いただけません。\nメールアドレス及びパスワードのご変更は、withLEAFアプリよりお手続き願います。";

const province_Array = [
  {
    "en": "Aichi",
    "jp": "愛知県"
  },
  {
    "en": "Akita",
    "jp": "秋田県"
  },
  {
    "en": "Aomori",
    "jp": "青森県"
  },
  {
    "en": "Chiba",
    "jp": "千葉県"
  },
  {
    "en": "Ehime",
    "jp": "愛媛県"
  },
  {
    "en": "Fukui",
    "jp": "福井県"
  },
  {
    "en": "Fukuoka",
    "jp": "福岡県"
  },
  {
    "en": "Fukushima",
    "jp": "福島県"
  },
  {
    "en": "Gifu",
    "jp": "岐阜県"
  },
  {
    "en": "Gunma",
    "jp": "群馬県"
  },
  {
    "en": "Hiroshima",
    "jp": "広島県"
  },
  {
    "en": "Hokkaidō",
    "jp": "北海道"
  },
  {
    "en": "Hyōgo",
    "jp": "兵庫県"
  },
  {
    "en": "Ibaraki",
    "jp": "茨城県"
  },
  {
    "en": "Ishikawa",
    "jp": "石川県"
  },
  {
    "en": "Iwate",
    "jp": "岩手県"
  },
  {
    "en": "Kagawa",
    "jp": "香川県"
  },
  {
    "en": "Yamanashi",
    "jp": "山梨県"
  },
  {
    "en": "Yamaguchi",
    "jp": "山口県"
  },
  {
    "en": "Kōchi",
    "jp": "高知県"
  },
  {
    "en": "Kumamoto",
    "jp": "熊本県"
  },
  {
    "en": "Kyōto",
    "jp": "京都府"
  },
  {
    "en": "Mie",
    "jp": "三重県"
  },
  {
    "en": "Yamagata",
    "jp": "山形県"
  },
  {
    "en": "Miyazaki",
    "jp": "宮崎県"
  },
  {
    "en": "Nagano",
    "jp": "長野県"
  },
  {
    "en": "Nagasaki",
    "jp": "長崎県"
  },
  {
    "en": "Nara",
    "jp": "奈良県"
  },
  {
    "en": "Niigata",
    "jp": "新潟県"
  },
  {
    "en": "Ōita",
    "jp": "大分県"
  },
  {
    "en": "Okayama",
    "jp": "岡山県"
  },
  {
    "en": "Okinawa",
    "jp": "沖縄県"
  },
  {
    "en": "Ōsaka",
    "jp": "大阪府"
  },
  {
    "en": "Saga",
    "jp": "佐賀県"
  },
  {
    "en": "Saitama",
    "jp": "埼玉県"
  },
  {
    "en": "Shiga",
    "jp": "滋賀県"
  },
  {
    "en": "Shimane",
    "jp": "島根県"
  },
  {
    "en": "Shizuoka",
    "jp": "静岡県"
  },
  {
    "en": "Tochigi",
    "jp": "栃木県"
  },
  {
    "en": "Tokushima",
    "jp": "徳島県"
  },
  {
    "en": "Tottori",
    "jp": "鳥取県"
  },
  {
    "en": "Toyama",
    "jp": "富山県"
  },
  {
    "en": "Tōkyō",
    "jp": "東京都"
  },
  {
    "en": "Miyagi",
    "jp": "宮城県"
  },
  {
    "en": "Wakayama",
    "jp": "和歌山県"
  },
  {
    "en": "Kanagawa",
    "jp": "神奈川県"
  },
  {
    "en": "",
    "jp": ""
  }
];


function AccountChangeField({ props }) {
  const classes = useStyles();
  const { useUpdateMarketUser } = useMarketUser();
  const useUpdateMarketUserMutation = useUpdateMarketUser().mutate;
  const history = useHistory();
  const user = props;

  function province() {
    const prov = province_Array.find((e) => e.en == user.defaultAddress.province );
      return prov.jp; 
    }

  const { values, touched, handleChange, handleSubmit, isValid, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        email: user ? user.email : "",
        lastName: user ? user.lastName || "" : "",
        firstName: user ? user.firstName || "" : "",
        phone: user.defaultAddress ? user.defaultAddress.phone.replace( '+81', '0' ) || "" : "",
        zip: user.defaultAddress ? user.defaultAddress.zip || "" : "",
        province: user.defaultAddress ? province() || "" : "",
        city: user.defaultAddress ? user.defaultAddress.city || "" : "",
        address1: user.defaultAddress ? user.defaultAddress.address1 || "" : "",
        address2: user.defaultAddress ? user.defaultAddress.address2 || "" : "",
      },
      validationSchema: validationSchema,
      onSubmit: onSubmit,
    });

  async function onSubmit() {
    try {
      await useUpdateMarketUserMutation(values);
      // history.push("/myaccount/change/success");
    } catch (err) {
      console.debug(err);
    }
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <StyledTextField
        width="30%"
        id="lastName"
        name="lastName"
        label="氏"
        color="secondary"
        required
        value={values.lastName}
        onChange={handleChange}
        error={touched.lastName && !!errors.lastName}
        helperText={!touched.lastName ? errors.lastName : ""}
        className={classes.form}
      />
      <StyledTextField
        width="30%"
        id="firstName"
        name="firstName"
        label="名"
        color="secondary"
        required
        value={values.firstName}
        onChange={handleChange}
        error={touched.firstName && !!errors.firstName}
        helperText={!touched.firstName ? errors.firstName : ""}
        className={classes.form}
      />

      <TextField
        fullWidth
        id="phone"
        name="phone"
        label="電話番号"
        type="phone"
        color="secondary"
        required
        value={values.phone}
        onChange={handleChange}
        error={touched.phone && !!errors.phone}
        helperText={!touched.phone ? errors.phone : ""}
        className={classes.form}
      />
      <TextField
        id="zip"
        name="zip"
        label="郵便番号"
        variant="outlined"
        color="secondary"
        required
        value={values.zip}
        onChange={handleChange}
        error={touched.zip && !!errors.zip}
        helperText={!touched.zip ? errors.zip : ""}
        className={classes.form}
      />
      {/* <StyledAddressSarchButton className={classes.sarchButton}>
        住所検索
      </StyledAddressSarchButton> */}

      <FormControl required className={classes.formControl}>
        <InputLabel id="select-province">都道府県</InputLabel>
        <Select
          id="province"
          name="province"
          label="都道府県"
          color="secondary"
          required
          value={values.province}
          onChange={handleChange}
          error={touched.province && !!errors.province}
          helperText={!touched.province ? errors.province : ""}
        >
          {/* <MenuItem value="">
            <em></em>
          </MenuItem> */}
          <MenuItem value="北海道">北海道</MenuItem>
          <MenuItem value="青森県">青森県</MenuItem>
          <MenuItem value="岩手県">岩手県</MenuItem>
          <MenuItem value="宮城県">宮城県</MenuItem>
          <MenuItem value="秋田県">秋田県</MenuItem>
          <MenuItem value="山形県">山形県</MenuItem>
          <MenuItem value="福島県">福島県</MenuItem>
          <MenuItem value="茨城県">茨城県</MenuItem>
          <MenuItem value="栃木県">栃木県</MenuItem>
          <MenuItem value="群馬県">群馬県</MenuItem>
          <MenuItem value="埼玉県">埼玉県</MenuItem>
          <MenuItem value="千葉県">千葉県</MenuItem>
          <MenuItem value="東京都">東京都</MenuItem>
          <MenuItem value="神奈川県">神奈川県</MenuItem>
          <MenuItem value="富山県">富山県</MenuItem>
          <MenuItem value="石川県">石川県</MenuItem>
          <MenuItem value="福井県">福井県</MenuItem>
          <MenuItem value="山梨県">山梨県</MenuItem>
          <MenuItem value="長野県">長野県</MenuItem>
          <MenuItem value="岐阜県">岐阜県</MenuItem>
          <MenuItem value="静岡県">静岡県</MenuItem>
          <MenuItem value="愛知県">愛知県</MenuItem>
          <MenuItem value="三重県">三重県</MenuItem>
          <MenuItem value="滋賀県">滋賀県</MenuItem>
          <MenuItem value="京都県">京都県</MenuItem>
          <MenuItem value="大阪府">大阪府</MenuItem>
          <MenuItem value="兵庫県">兵庫県</MenuItem>
          <MenuItem value="奈良県">奈良県</MenuItem>
          <MenuItem value="和歌山県">和歌山県</MenuItem>
          <MenuItem value="鳥取県">鳥取県</MenuItem>
          <MenuItem value="島根県">島根県</MenuItem>
          <MenuItem value="岡山県">岡山県</MenuItem>
          <MenuItem value="広島県">広島県</MenuItem>
          <MenuItem value="山口県">山口県</MenuItem>
          <MenuItem value="徳島県">徳島県</MenuItem>
          <MenuItem value="香川県">香川県</MenuItem>
          <MenuItem value="愛媛県">愛媛県</MenuItem>
          <MenuItem value="高知県">高知県</MenuItem>
          <MenuItem value="福岡県">福岡県</MenuItem>
          <MenuItem value="佐賀県">佐賀県</MenuItem>
          <MenuItem value="長崎県">長崎県</MenuItem>
          <MenuItem value="熊本県">熊本県</MenuItem>
          <MenuItem value="大分県">大分県</MenuItem>
          <MenuItem value="宮崎県">宮崎県</MenuItem>
          <MenuItem value="鹿児島県">鹿児島県</MenuItem>
          <MenuItem value="沖縄県">沖縄県</MenuItem>
        </Select>
      </FormControl>

      <TextField
        sx={9}
        id="city"
        name="city"
        label="市区町村"
        color="secondary"
        required
        value={values.city}
        onChange={handleChange}
        error={touched.city && !!errors.city}
        helperText={!touched.city ? errors.city : ""}
        className={classes.form}
      />
      <TextField
        fullWidth
        id="address1"
        name="address1"
        label="ご住所"
        color="secondary"
        required
        value={values.address1}
        onChange={handleChange}
        error={touched.address1 && !!errors.address1}
        helperText={!touched.address1 ? errors.address1 : ""}
        className={classes.form}
      />
      <TextField
        fullWidth
        id="address2"
        name="address2"
        label="マンション建物名・部屋番号"
        color="secondary"
        value={values.address2}
        onChange={handleChange}
        className={classes.form}
      />
      <StyledSubmmitButton
        variant="contained"
        fullWidth
        className={classes.form}
        type="submit"
        disabled={!isValid}
      >
        変 更 内 容 を 送 信
      </StyledSubmmitButton>
      <Link to="/myaccount"
        className="Link">
        <StyledReturnButton
          variant="contained"
          fullWidth
          className={classes.form}
        >
          変 更 せ ず に 戻 る
        </StyledReturnButton>
      </Link>
    </form>
  );
}
export default function AccountChange() {
  const classes = useStyles();
  const { useGetUser } = useUser();
  const { data: User } = useGetUser();

  return (
    <div>
      <script
        src="https://yubinbango.github.io/yubinbango/yubinbango.js"
        charSet="UTF-8"
      ></script>
      <ThemeProvider theme={theme}>
        <ButtonAppBar />
        <Container component="main" maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item className={classes.grid}>
              <Divider className={classes.divider} />
              <Typography
                component="h2"
                variant="h4"
                style={{ textAlign: "center" }}
              >
                お客様情報のご変更
              </Typography>
              <Divider className={classes.divider} />
              {/* 改行を入れる */}
              <div style={{ whiteSpace: "pre-line" }}>
                <Typography
                  className={classes.form}
                  variant="body1"
                  style={{ fontSize: 14 }}
                >
                  {info}
                </Typography>
                <Typography
                  className={classes.form}
                  variant="body1"
                  style={{ fontSize: 10 }}
                >
                  {caption}
                </Typography>
              </div>
              <Box sx={{ height: 30 }}></Box>
              <div>
                {!User ? (
                  <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
                ) : (
                  <AccountChangeField props={User[0]} key={User[0].email} />
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

import { createTheme } from '@material-ui/core/styles';
import toast from 'react-hot-toast';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#8fc31f' }, // テーマの色
  },
});


  export const useToast = () => {
    const showToast = (
      funcName,
      title,
      message,
      duration
      ) => {
        if (funcName === 'success') {
          toast.success(
            message,{
              style: {
                width: '500px',
                height: '70px',
                fontSize: '15px',
                fontWeight: 'bold',
                },
              duration: duration,
            });
        } else if (funcName === 'error') {
          toast.error(
            message, {
              style: {
                width: '500px',
                height: '70px',
                fontSize: '15px',
                fontWeight: 'bold',
              },
            duration: duration,
          });
        }
      };
    return { showToast };
  };

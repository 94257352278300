import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, createTheme, Grid, ThemeProvider, Typography, withStyles } from '@material-ui/core';
import ButtonAppBar from '../modules/AppBar';
import Footer from '../modules/Footer';
import FloatingBtn from '../modules/assets/FloatingButton';

const theme = createTheme({
  button: {
    fontFamily: [
      'ヒラギノ角ゴ ProN',
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
    palette: {
      primary: {
        light: '#e6eae6',
        main: '#d3d3d8',
        dark: '#c9caca',
        contrastText: '#626063',
      },
      secondary: {
        light: '#ff7f50',
        main: '#ff4500',
        dark: '#cc3600',
        contrastText: '#ffffff',
      },
    },
  });

const StyledTableCell = withStyles({
  body: {
    fontSize: "small",
  },
})(TableCell);

const StyledTableCell2 = withStyles({
  body: {
    fontSize: "small",
    border: "none",
  },
})(TableCell);

const BootButton = withStyles({
  root: {
    fontSize: 14,
    padding: '12px 16px',
    width: 200,
    lineHeight: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
})(Button);


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },

  table: {
    minWidth: "auto",
    backgroundColor: "#fff9f5",
  },

  grid: {
    margin: "auto",
    // marginRight: theme.spacing(2),
    // marginLeft: theme.spacing(2),
  },
}));

const createData　= (title, detail) => {
  return { title, detail };
}

const rows = [
  createData('クレジットカード', 'VISA, Mastercard, AmericanExpress, JCBブランドでのカード決済が可能です。（一括払いのみ申し受けます。）'),
  createData('GMO後払い', '商品出荷後、商品とは別に払込票を郵送いたしますので、お近くのコンビニエンスストア・銀行・LINE Payにてお支払いください。請求書発行から14日以内にお支払いください。お支払い期限は請求書にも 記載しております。\n手数料は無料です（銀行振込の場合の手数料はお客様負担となります）。\n以下のコンビニエンスストアにおける支払いに対応しています。\nファミリーマート、ローソン、セブンイレブン、ミニストップ\n\n●GMO後払いのご注意\n・ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。\n・ご利用にあたり審査がございます。審査結果によっては「GMO後払い」をご利用いただけない場合がございますので、その場合には別のお支払方法へ変更をお願いします。\n・「GMO後払い」はGMOペイメントサービス株式会社が提供するサービスです。当社はGMOペイメントサービス株式会社に対しサービスの範囲内で個人情報を提供し、代金債権を譲渡します。\n・ご利用限度額はGMO後払い累計で、55,000円（税込）です。\n・商品のお届け先が「運送会社留め」「郵便局留め」、また「学校」「病院」「ホテル」など一時滞在となる場合は、GMO後払いをご利用いただけません。商品の転送サービスもご利用いただけません。\n※お届け先が勤務先の場合はご利用いただけますのでご住所の後に「勤務先」とご入力ください。\n※ギフトのお届け先が一時滞在となるご住所の場合にはご利用になれます。'),
  createData('代金引換（代引き）', '商品ご到着時に代金をお支払いいただく方法です。佐川急便でのお届けとなります。お受け取りの際、宅配便のドライバーに現金で代金をお支払いください。\nシステムの関係上、代引き手数料がお支払合計に表示されていませんが、代引き手数料330円（税込）が発生しますので、予めご了承ください。'),
];

const rows2 = [
  createData('配送について', '配送業者: ヤマト運輸または、佐川急便にてお届けします。\n出荷日数: 通常、ご注文後、翌営業日に出荷いたします。但し在庫や配送の都合上で遅れることがございます。また、ネコポスの場合には出荷からお届けまで10日程度かかることがございますので、予めご了承ください。\n\n配送業者を予告なく変更する可能性がありますので、予めご了承ください。'),
  createData('お届け日時のご指定について', 'お届け時間帯は、下記よりご指定いただけます。\n\n午前中（8時～12時）\n12時～14時\n14時～16時\n16時～18時\n18時～21時\n19時～21時\n\nただし、ネコポスなどポスト投函によるお届けの場合にはお時間帯のご指定はいただけませんのでご了承ください。'),
  createData('送料について', '送料660円（税込）\nただし、一度のご注文で、商品代金4,980円（税込）以上お支払いで、送料無料になります。ポイント値引きご利用後のお支払い金額が4,980円（税込）以上で送料無料の対象となりますので、ご了承ください。\n沖縄への配送は、ご購入金額にかかわらず、一律1,100円（税込）となります。\n離島の場合、中継料金が別途発生する可能性がありますので、その場合は発送前にご連絡させていただきます。'),
];

const rows3 = [
  createData('手順','1.：商品のお受取りから8日以内に次の情報をお問い合わせフォームより送信してください。\n\n・お名前\n・ご住所\n・ご連絡用メールアドレス\n・注文番号（送信された注文確認メールに記載されております）\n\n2.：内容の確認後、条件を満たすと認められた場合のみ、ご返品、交換または返金手続きをご案内します。\n\n\n※お問い合わせ後、３営業日以内に返信がない場合は、お手数ですが再度ご連絡ください。\n\n※迷惑メール対策により、ご連絡のメールが正常に届かない場合がありますので、以下のメールが受信できることをご確認ください。\n  iw-shop@iwabuchi-net.co.jp\n\n※メールサービス、メールソフト、ウイルス対策ソフトなどによっては、迷惑メールとして認識され、正しく受信されていない場合があります。（特にHotmailなどの無料の電子メールサービスは、迷惑メールとして認識されることがありますので、メールボックスを確認するか、ソフトウェアの設定やサービスを確認してください）',),
  createData('ご注意','以下の条件に合う商品のご返品、ご交換またはご返金は、お受けできませんのでご了承ください。\n\n・お届け後8日以上経過した商品\n・開封後の商品\n・ご使用になられた商品\n・セール商品\n・受注生産品\n・お客様が破損・汚損された商品\n・お客様都合による返品・交換（イメージ違い・サイズ違い・誤注文など）\n・そのほか、ご注文時に「返品不可」の記載のあった商品\n\nなお、商品不良および損傷による返品にかかる送料は弊社が負担させていただきます。\n\n※ご覧になっているモニターやディスプレイ環境により、実際の商品と色味、質感などが異なって見える場合がございます。',),
];

export default function UsersguidTable() {
  const classes = useStyles();

  return (
    <div>
      <ThemeProvider theme={theme}>
      <ButtonAppBar/>
        <Grid container justifyContent="center">
          <Grid item className={classes.grid} xs={10} >
          <Box 
                sx={{
                    marginTop: 80,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
        　　>
              <Typography
                  component="h1" variant="h4">
                  ご利用案内
              </Typography>
            </Box>
            <p></p>
            <Grid container justifyContent="center">
            <div className={classes.root}>
              <BootButton size="large" variant="contained"
                onClick={() => {
                  window.location.href = "#shiharai";
                }}>
                お支払いについて
              </BootButton>
              <BootButton size="large" variant="contained" href="#haiso">
                配送･送料について
              </BootButton>
              <BootButton size="large" variant="contained" href="#henpin">
                返品交換について
              </BootButton>
              <BootButton size="large" variant="contained" href="#cansel">
                キャンセルについて
              </BootButton>
              <BootButton size="large" variant="contained" href="#privacy">
                個人情報について
              </BootButton>
            </div>
            </Grid>
            <Box sx={{height: 75,}} id="shiharai"></Box>
            {/* 改行を入れる */}
            <div style={{whiteSpace: 'pre-line'}}>
              <TableContainer component={Paper} >
                <Table className={classes.table} size="medium" aria-label="a dense table">       
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        <h2>お支払いについて</h2>
                          <p>お支払い方法は、クレジットカード、後払い、代金引換（代引き）よりお選びいただけます。</p>
                          <p>ポイント値引きは、商品合計金額の10％がご利用上限となります（送料はポイント利用の対象外です）。</p>
                      </StyledTableCell>
                    </TableRow>
                      {rows.map((row) => (
                        <TableRow key={row.title}>
                          <StyledTableCell component="th" scope="row" color='primary' border='none'>
                            {row.title}
                          </StyledTableCell>
                          <StyledTableCell >
                            {row.detail}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box sx={{height: 75,}} id="haiso"></Box>
            {/* 改行を入れる */}
            <div style={{whiteSpace: 'pre-line'}}>
              <TableContainer component={Paper} >
                <Table className={classes.table} size="medium" aria-label="a dense table">       
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        <h2>配送・送料について</h2>
                      </StyledTableCell>
                    </TableRow>
                    {rows2.map((row2) => (
                      <TableRow key={row2.title}>
                        <StyledTableCell component="th" scope="row2" color='primary' >
                          {row2.title}
                        </StyledTableCell>
                        <StyledTableCell >
                          {row2.detail}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box sx={{height: 75,}} id="henpin"></Box>
            <div style={{whiteSpace: 'pre-line'}}>
              <TableContainer component={Paper} >
                <Table className={classes.table} size="medium" aria-label="a dense table">       
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        <h2>商品の返品・交換・返金について</h2>
                        <p></p>
                        <p>返品・不良品の場合は、商品到着後8日以内にお問い合せフォームよりご連絡ください。確認後、不良品の交換または返金にて対応させていただきます。</p>
                        <p>返品・交換のご連絡なく、商品をご返送いただきましても、お受けできませんので必ずご一報ください。</p>
                      </StyledTableCell>
                    </TableRow>
                    {rows3.map((row3) => (
                      <TableRow key={row3.title}>
                        <StyledTableCell omponent="th" scope="row3" color='primary' >
                          {row3.title}
                        </StyledTableCell>
                        <StyledTableCell >
                          {row3.detail}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box sx={{height: 75,}} id="cansel"></Box>
            <div style={{whiteSpace: 'pre-line'}}>
              <TableContainer component={Paper} >
                <Table className={classes.table} size="medium" aria-label="a dense table">       
                  <TableBody>
                    <TableRow>
                      <StyledTableCell2 colSpan={2}>
                        <h2>ご注文のキャンセルについて</h2>
                        <p></p>
                        <p>ご注文商品の出荷後の注文キャンセルにつきましては、基本的にお受けすることができませんので、予めご了承ください。</p>
                        <p>出荷前のキャンセルにつきましては、<a href="/privacy" className='Link'>お問い合わせフォーム</a>よりご連絡ください。キャンセル内容などによりお受けできない可能性もございますので、予めご了承ください。</p>
                      </StyledTableCell2>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box sx={{height: 75,}} id="privacy"></Box>
            <div style={{whiteSpace: 'pre-line'}}>
              <TableContainer component={Paper} >
                <Table className={classes.table} size="medium" aria-label="a dense table">       
                  <TableBody>
                    <TableRow>
                      <StyledTableCell2 colSpan={2}>
                        <h2>個人情報について</h2>
                        <p></p>
                        <p><a href="/privacy" className='Link'>プライバシーポリシー</a>をご覧ください。</p>
                      </StyledTableCell2>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box sx={{height: 30,}}></Box>
          </Grid>
        </Grid>
        <FloatingBtn />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

import { useQuery } from 'react-query';
import { useToast } from '../../components/molecules/toastMolecules';
import { commonHttpClient } from './commonHttpClient';


// 全商品情報取得
export const useProducts = () => {
  const { http } = commonHttpClient();

  const getProducts = async () => {
    let productData = {};
    await http
      .get('/products')
      .then((response) => {
        productData = response.data;
      })
      .catch((err) => {
        console.debug("商品情報取得失敗:", err.response);
      });
    console.debug(typeof productData);
    return productData ;
  };

  const useGetProducts = () => useQuery("productsInfo", getProducts );

  return {
    useGetProducts,
  };
};




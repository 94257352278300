// import { AxiosError } from 'axios';
import { commonHttpClient } from "./commonHttpClient";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useCookies } from "react-cookie";
import { useToast } from "../../components/molecules/toastMolecules";
import { COOKIE_NAME } from "../../utils/CommonConstants";
import { Toaster } from "react-hot-toast";

export const useAuth = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();
  const cname = COOKIE_NAME;
  const [cookies, setCookie, removeCookie] = useCookies([cname]);

  const getAuth = async () => {
    let usersData = {};
    await http
      .get("/auth/me")
      .then((response) => {
        usersData = response;
      })
      .catch((err) => {
        console.log("現在ログインしていません:", err.response);
      });
    return usersData.data;
  };

  const requestLogin = async ({ email, password }) => {
    await http
      .post("/auth/login", {
        email,
        password,
      })
      // .then((response) => {
      //   console.debug(response);
      //   const token = response.data.token;
      //   setCookie(cname, token, {
      //     path: "/",
      //     secure: true,
      //     sameSite: "None",
      //   });
      // })
      .catch((err) => {
        console.debug(err);
        if (err.response.status == 401) {
          throw new Error("ユーザ名/パスワードのいずれかに誤りがあります");
        }
      });
  };

  const requestLogout = async () => {
    // setCookie(cname, "", {
    //   path: "/",
    //   secure: true,
    //   sameSite: "None",
    // });
    await http.post("/auth/logout");
  };

  const useGetAuth = () => useQuery("LoginUser", getAuth);

  const useRequestLoginMutation = () =>
    useMutation(requestLogin, {
      onSuccess: async () => {
        showToast(
          'success',
          'ログインに成功しました',
          'ログインに成功しました！',
          3000
        );
        setTimeout(() => {
          queryClient.invalidateQueries("LoginUser");
        }, 1400);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            'error', 
            'ログインに失敗しました', 
            e.message, 
            3000)
          throw e;
        }
      },
    });

  const useRequestLogoutMutation = () =>
    useMutation(requestLogout, {
      onSuccess: async () => {
        setTimeout(() => {
          queryClient.invalidateQueries("LoginUser");
        }, 3000);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            "error", 
            "ログアウトに失敗しました",
             e.message, 
             3000)
          throw e;
        }
      },
    });

  return {
    useGetAuth,
    useRequestLoginMutation,
    useRequestLogoutMutation,
  };
};

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import LoadingBubbleOrganisms from '../components/organisms/LoadingBubbleOrganisms';
import { useAuth } from '../controllers/services/UseAuth';


const PrivateRoute = ( props ) => {
  const { useGetAuth } = useAuth();
  const { isLoading, data, isError, error } = useGetAuth();

  if (isLoading) {
    return (

        <LoadingBubbleOrganisms />

    );
  }

  if (data === undefined || !Object.keys(data).length) {
    return <Redirect to={'/login'} />;
  }

  // const decodedTokenStr = localStorage.getItem('decoded_token');
  // const decodedToken: DecodedToken = JSON.parse(decodedTokenStr || '');

  return <Route {...props} />;
};
export default PrivateRoute;

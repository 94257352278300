import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  Container,
  createTheme,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useUser } from "../../../controllers/services/UseUser";
import LoadingBubbleOrganisms from "../../organisms/LoadingBubbleOrganisms";
import ButtonAppBar from "../../modules/AppBar";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  SndIcon: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
}));

const StyledTableCell = withStyles({
  body: {
    fontSize: "small",
  },
})(TableCell);

const StyledSubmmitButton = withStyles({
  root: {
    fontSize: 12,
    padding: "10px 14px",
    lineHeight: 2,
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
})(Button);

const createData = (title, detail) => {
  return { title, detail };
};

const info =
  "お客様の現在のご登録は下記のようになっております。\nご変更がある場合には「ご登録内容のご変更」ボタンを押してください。";

const province_Array = [
  {
    "en": "Aichi",
    "jp": "愛知県"
  },
  {
    "en": "Akita",
    "jp": "秋田県"
  },
  {
    "en": "Aomori",
    "jp": "青森県"
  },
  {
    "en": "Chiba",
    "jp": "千葉県"
  },
  {
    "en": "Ehime",
    "jp": "愛媛県"
  },
  {
    "en": "Fukui",
    "jp": "福井県"
  },
  {
    "en": "Fukuoka",
    "jp": "福岡県"
  },
  {
    "en": "Fukushima",
    "jp": "福島県"
  },
  {
    "en": "Gifu",
    "jp": "岐阜県"
  },
  {
    "en": "Gunma",
    "jp": "群馬県"
  },
  {
    "en": "Hiroshima",
    "jp": "広島県"
  },
  {
    "en": "Hokkaidō",
    "jp": "北海道"
  },
  {
    "en": "Hyōgo",
    "jp": "兵庫県"
  },
  {
    "en": "Ibaraki",
    "jp": "茨城県"
  },
  {
    "en": "Ishikawa",
    "jp": "石川県"
  },
  {
    "en": "Iwate",
    "jp": "岩手県"
  },
  {
    "en": "Kagawa",
    "jp": "香川県"
  },
  {
    "en": "Yamanashi",
    "jp": "山梨県"
  },
  {
    "en": "Yamaguchi",
    "jp": "山口県"
  },
  {
    "en": "Kōchi",
    "jp": "高知県"
  },
  {
    "en": "Kumamoto",
    "jp": "熊本県"
  },
  {
    "en": "Kyōto",
    "jp": "京都府"
  },
  {
    "en": "Mie",
    "jp": "三重県"
  },
  {
    "en": "Yamagata",
    "jp": "山形県"
  },
  {
    "en": "Miyazaki",
    "jp": "宮崎県"
  },
  {
    "en": "Nagano",
    "jp": "長野県"
  },
  {
    "en": "Nagasaki",
    "jp": "長崎県"
  },
  {
    "en": "Nara",
    "jp": "奈良県"
  },
  {
    "en": "Niigata",
    "jp": "新潟県"
  },
  {
    "en": "Ōita",
    "jp": "大分県"
  },
  {
    "en": "Okayama",
    "jp": "岡山県"
  },
  {
    "en": "Okinawa",
    "jp": "沖縄県"
  },
  {
    "en": "Ōsaka",
    "jp": "大阪府"
  },
  {
    "en": "Saga",
    "jp": "佐賀県"
  },
  {
    "en": "Saitama",
    "jp": "埼玉県"
  },
  {
    "en": "Shiga",
    "jp": "滋賀県"
  },
  {
    "en": "Shimane",
    "jp": "島根県"
  },
  {
    "en": "Shizuoka",
    "jp": "静岡県"
  },
  {
    "en": "Tochigi",
    "jp": "栃木県"
  },
  {
    "en": "Tokushima",
    "jp": "徳島県"
  },
  {
    "en": "Tottori",
    "jp": "鳥取県"
  },
  {
    "en": "Toyama",
    "jp": "富山県"
  },
  {
    "en": "Tōkyō",
    "jp": "東京都"
  },
  {
    "en": "Miyagi",
    "jp": "宮城県"
  },
  {
    "en": "Wakayama",
    "jp": "和歌山県"
  },
  {
    "en": "Kanagawa",
    "jp": "神奈川県"
  },
  {
    "en": "Kagoshima",
    "jp": "鹿児島県"
  },
  {
    "en": "",
    "jp": ""
  }
];

export default function AccountCheck() {
  const classes = useStyles();
  const { useGetUser } = useUser();
  const { data: User } = useGetUser();
  let rows = [
    createData("氏名", "..."),
    createData("メールアドレス", "..."),
    createData("電話番号", "..."),
    createData("郵便番号", "〒" + "..."),
    createData("ご住所", "..."),
  ];

  if (User) {
    const { lastName, firstName, displayName, email, defaultAddress } = User[0];
    // const nameString =
    //   firstName != null ? firstName + " " + lastName : firstName;
    const nameString = displayName;
    rows = [
      createData("氏名", nameString),
      createData("メールアドレス", email),
      // createData("電話番号", defaultAddress.phone || ""),
      // createData("郵便番号", "〒" + defaultAddress.zip || ""),
      // createData("ご住所", displayAddress || ""),
    ];
    if (defaultAddress) {
      function province() {
        const prov = province_Array.find((e) => e.en == defaultAddress.province );
          return prov.jp; 
        }
      let displayAddress =
        "" +
        (defaultAddress.company || "") +
        " " +
        (province() || "") +
        " " +
        (defaultAddress.city || "") +
        " " +
        (defaultAddress.address1 || "") +
        " " +
        (defaultAddress.address2 || "");
      rows.push(createData("電話番号", defaultAddress.phone.replace( '+81', '0' ) || ""));
      rows.push(createData("郵便番号", defaultAddress.zip || ""));
      rows.push(createData("ご住所", displayAddress || ""));
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <ButtonAppBar />
      <Container component="main" maxWidth="sm">
        <Grid container justifyContent="center">
          <Grid item className={classes.grid}>
            <Divider className={classes.divider} />
            <Typography component="h2" variant="h4">
              お客様情報のご確認
            </Typography>
            <Divider className={classes.divider} />
            {/* 改行を入れる */}
            <div style={{ whiteSpace: "pre-line" }}>
              <Typography variant="body1" style={{ fontSize: 14 }}>
                {info}
              </Typography>
              <Box sx={{ height: 30 }}></Box>
              {User ? (
                <form>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="medium"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.title}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              color="primary"
                            >
                              {row.title}
                            </StyledTableCell>
                            <StyledTableCell>{row.detail}</StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Link to="/myaccount/change"
                    className="Link">
                    <StyledSubmmitButton
                      variant="contained"
                      fullWidth
                      className={classes.submitButton}
                    >
                      ご 登 録 内 容 の ご 変 更
                    </StyledSubmmitButton>
                  </Link>
                  <Link
                    to="/myaccount"
                    className="btn-border-bottom"
                    style={{
                      marginTop: theme.spacing(4),
                      marginBottom: theme.spacing(4),
                    }}
                  >
                    マイページに戻る
                  </Link>
                </form>
              ) : (
                <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Container,
  createTheme,
  Divider,
  Grid,
  TableHead,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import FloatingBtn from "../../modules/assets/FloatingButton";
import { useOrders } from "../../../controllers/services/UseOrders";
import { useUser } from "../../../controllers/services/UseUser";
import LoadingBubbleOrganisms from "../../organisms/LoadingBubbleOrganisms";
import moment from "moment";
import "moment/locale/ja";
import ButtonAppBar from "../../modules/AppBar";
import Footer from "../../modules/Footer";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  SndIcon: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledTableCell = withStyles({
  body: {
    fontSize: "small",
  },
})(TableCell);

function createData(order_no, shoppingday, sum, fulfillment, detail, id) {
  return { order_no, shoppingday, sum, fulfillment, detail, id };
}

function OrdersRow({ props }) {
  const classes = useStyles();
  const email = props;
  const { getOrders } = useOrders();
  const { data, isLoading, isError } = useQuery(
    ["myOrders", email],
    // () => getOrders("nakayama-a@iwabuchi-net.co.jp"), // テスト用(購入履歴データがあるアカウント)
    () => getOrders(email),
    { retry: false, refetchOnWindowFocus: false }
  );

  let rows = [];

  if (isLoading) {
    return (
      <div>
        <Typography>購入データ取得中...少々お待ちください</Typography>
        <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
      </div>
    );
  }
  if (isError) {
    return (
      <TableRow key="empty" textAlign="center">
        ご注文履歴の取得に失敗しました。再度画面を読み込んでください。
      </TableRow>
    );
  }
  if ( data) {
    if ( data.length === 0) {
      return (
        <TableRow key="empty" textAlign="center">
        ご注文履歴はありません。
        </TableRow>
    )
  } else {
    data.forEach((order) => {
      rows.push(
        createData(
          order.name,
          order.createdAt,
          order.totalAmount,
          order.status,
          "詳細",
          order.id
        )
      );
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>注文番号</TableCell>
            <TableCell>ご購入日</TableCell>
            <TableCell>合計金額</TableCell>
            <TableCell>配送状況</TableCell>
            <TableCell>詳細</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.order_no}>
              <StyledTableCell component="th" scope="row" color="primary">
                {row.order_no}
              </StyledTableCell>
              <StyledTableCell>
                {moment
                  .utc(row.shoppingday)
                  .local()
                  .format("YYYY年MM月DD日(dd) HH:mm")
                  .toString()}
              </StyledTableCell>
              <StyledTableCell>{Math.floor(row.sum)}円</StyledTableCell>
              <StyledTableCell>
                {row.fulfillment === "FULFILLED"
                  ? "発送済み"
                  : row.fulfillment === "PARTIALLY_FULFILLED"
                  ? "一部発送済み"
                  : row.fulfillment === "UNFULFILLED"
                  ? "未発送"
                  : row.fulfillment}
              </StyledTableCell>
              <Link
                to={{
                  pathname: "/myaccount/myorder",
                  search: `?gid=${row.id}`,
                }}
              >
                <StyledTableCell>{row.detail}</StyledTableCell>
              </Link>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}}

export default function MyOrders() {
  const classes = useStyles();
  const { useGetUser } = useUser();
  const { data: User } = useGetUser();
  const [email, setEmail] = useState("");

  if (!User) {
    return <LoadingBubbleOrganisms></LoadingBubbleOrganisms>;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <ButtonAppBar/>
        <Container component="main" maxWidth="md">
          <Grid container justifyContent="center">
            <Grid item className={classes.grid}>
              <Divider className={classes.divider} />
              <Typography component="h2" variant="h4">
                ご注文履歴
              </Typography>
              <Divider className={classes.divider} />
              <form>
                <OrdersRow key={User[0].firstName} props={User[0].email} />
              </form>
              <Link
                to="/myaccount"
                className="btn-border-bottom"
                style={{
                  marginTop: theme.spacing(4),
                  marginBottom: theme.spacing(4),
                }}
              >
                マイページに戻る
              </Link>
              <FloatingBtn />
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }
}

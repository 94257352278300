import React from "react";
import ReactLoading from "react-loading";

const LoadingBubbleOrganisms = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <ReactLoading type="bubbles" color="#89B929" height="20vh" width="20vh" />
    </div>
  );
};
export default LoadingBubbleOrganisms;

import React from 'react';
import { useCheckout } from '../../controllers/services/UseCheckout';


function LineItem(props) {

    const checkoutId = props.checkoutId;
    const variantId = props.line_item.node.variant.id;
    
    const { useAddItemCheckout } = useCheckout();
    const useAddItemCheckoutMutation = useAddItemCheckout().mutate;
  
    const { useRemoveItemCheckout } = useCheckout();
    const useRemoveItemCheckoutMutation = useRemoveItemCheckout().mutate;

    const removeLineItemInCart = async() => {
      const lineItemIds = props.line_item.node.id;
      try {
        await useRemoveItemCheckoutMutation({ lineItemIds,checkoutId });
      } catch (err) {
        console.debug(err);
      }
    }

    const decrementQuantity = async() => {
      const quantity = -1
      const lineItems = { variantId, quantity };
      try {
        await useAddItemCheckoutMutation({ lineItems,checkoutId });
      } catch (err) {
        console.debug('失敗',err);
      }
    }
  
    const incrementQuantity = async() => {
      const quantity = 1
      const lineItems = { variantId, quantity };
      try {
        await useAddItemCheckoutMutation({ lineItems,checkoutId });
      } catch (err) {
        console.debug('失敗',err);
      }
    }
    
    return (
      <li className="Line-item">
        <div className="Line-item__img">
          {props.line_item.node.variant.image ? <img src={props.line_item.node.variant.image.url} alt={`${props.line_item.node.title} product shot`}/> : null}
        </div>
        <div className="Line-item__content">
          <div className="Line-item__content-row">
            {/* <div className="Line-item__variant-title">
              {props.line_item.node.variant.title}
            </div> */}
            <span className="Line-item__title">
              {props.line_item.node.title}
            </span>
          </div>
          <div className="Line-item__content-row">
            <div className="Line-item__quantity-container">
              <button className="Line-item__quantity-update" onClick={() => decrementQuantity(checkoutId)}>-</button>
              <span className="Line-item__quantity">{props.line_item.node.quantity}</span>
              <button className="Line-item__quantity-update" onClick={() => incrementQuantity(checkoutId)}>+</button>
            </div>
            <span className="Line-item__price">
              {Math.floor((props.line_item.node.quantity * props.line_item.node.variant.price.amount).toFixed(2))} 円
            </span>
            <button className="Line-item__remove" onClick={()=> removeLineItemInCart(checkoutId)}>削除</button>
          </div>
        </div>
      </li>
    );
  }

export default LineItem;

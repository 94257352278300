/* eslint-disable */
import React from 'react';
import Cart from './Cart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import logo from '../img/logo/LEAFcafe_logo.jpg';
import { AppBar, createTheme, Link, makeStyles, ThemeProvider, Toolbar, withStyles } from '@material-ui/core';
import Image from "../img/logo/LEAFcafe_logo.jpg";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#89B929",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      backgroundColor: "#ffffff",
      position: "relative",
      zIndex: theme.zIndex.drawer + 1,
      // paddingRight: 24,
    },
  }));

  export default function Header()  {
    const classes = useStyles();

      return (
        <div>
        <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <AppBar position="fixed">
          <Toolbar className={classes.toolbar}>           
            <Link to="/index">
              <img
                src={Image}
                alt="LEAFcafeロゴ"
              />
            </Link>
            </Toolbar>
        </AppBar>
        </div>
       </ThemeProvider>
     </div>
      );
    };
   


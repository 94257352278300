import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hashlink';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import EmailIcon from '@material-ui/icons/Email';
import { createTheme, ThemeProvider, withStyles, Typography, IconButton } from "@material-ui/core";



const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
    palette: {
      primary: {
        light: "#e6eae6",
        main: "#d3d3d8",
        dark: "#c9caca",
        contrastText: "#626063",
      },
      secondary: {
        light: "#ff7f50",
        main: "#89B929",
        dark: "#cc3600",
        contrastText: "#ffffff",
      },
    },
  });

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  points:{
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
});

// const StyledLink = withStyles((theme) => ({
//   root: {
//     color: theme.palette.primary.contrastText,
//     '&:hover': {
//       color: theme.palette.secondary.main,
//     },
//    },
//   }))(Link);

  const StyledTypography= withStyles((theme) => ({
    root: {
      color: theme.palette.secondary.main,
     },
    }))(Typography);

  const StyledMenuIcon = withStyles((theme) => ({
      root: {
        fontSize: 24,
        color: theme.palette.primary.contrastText,
        "&:hover": {
          color: theme.palette.secondary.main,
        },
        "&:active": {
          color: theme.palette.secondary.main,
        },
      },
    }))(MenuIcon);

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });

  const { points } = props;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom"
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >      
      <List>
        <Link
          to={{
            pathname: "/products",
            search: `?cid=0`,
          }}
          className="Link"
        >
        <ListItem button key={"ichiran"}>
          <ListItemIcon 
            style={{minWidth:30}}> 
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText primary={"商品一覧"} />
        </ListItem>
        </Link>
        {/*パスを設定*/}
        <Link
          to={{
            pathname: "/products",
            search: `?cid=1`,
          }}
          className="Link"
        >
          <ListItem button　key={"new"} >
            <ListItemText primary="食事の質を上げたい方に" />
          </ListItem>
        </Link>
        {/*パスを設定*/}
        <Link
          to={{
            pathname: "/products",
            search: `?cid=2`,
          }}
          className="Link"
        >
          <ListItem button　key={"health"} >
            <ListItemText primary="健康に過ごしたい方に" />
          </ListItem>
        </Link>
        {/*パスを設定*/}
        <Link
          to={{
            pathname: "/products",
            search: `?cid=3`,
          }}
          className="Link"
        >
          <ListItem button　key={"meal"} >
            <ListItemText primary="千葉県の商品" />
          </ListItem>
        </Link>
        {/*パスを設定*/}
        <Link
          to={{
            pathname: "/products",
            search: `?cid=4`,
          }}
          className="Link"
        >
          <ListItem button　key={"free"} >
            <ListItemText primary="送料無料" />
          </ListItem>
        </Link>
        {/*パスを設定*/}
        <Link 
          to={{
            pathname: "/products",
            search: `cid=0`,
          }}
          className="Link" >
          <ListItem button　key={"all"} >
            <ListItemText primary="全商品" />
          </ListItem>
        </Link>
        <Link to="/usersguid" className="Link">
        <ListItem button key={"guid"}>
          <ListItemIcon
            style={{minWidth:30}}>
            <ChromeReaderModeIcon />
          </ListItemIcon>
          <ListItemText primary={"ご利用案内"} />
        </ListItem>
        </Link>
        {/*パスを設定*/}
        <HashLink to="/usersguid#shiharai" className="Link">
          <ListItem button　key={"payment"} >
            <ListItemText primary="お支払いについて" />
          </ListItem>
        </HashLink>
        <HashLink to="/usersguid#haiso" className="Link">
          <ListItem button　key={"postage"} >
            <ListItemText primary="配送・送料について" />
          </ListItem>
        </HashLink>
        <HashLink to="/usersguid#henpin" className="Link">
          <ListItem button　key={"returns"} >
            <ListItemText primary="返品交換について" />
          </ListItem>
        </HashLink>
        <HashLink to="/usersguid#cansel" className="Link">
          <ListItem button　key={"cancel"} >
            <ListItemText primary="キャンセルについて" />
          </ListItem>
        </HashLink>
        <HashLink to="/usersguid#privacy" className="Link">
          <ListItem button　key={"personal"} >
            <ListItemText primary="個人情報について" />
          </ListItem>
        </HashLink>
        <Link to="/contact" className="Link">
          <ListItem button key={"question"} >
            <ListItemIcon
              style={{minWidth:30}}>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="お問い合わせ" />
          </ListItem>
        </Link>
      </List> 
      <Divider />
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <StyledMenuIcon />
            </IconButton>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
            <StyledTypography className={classes.points}>
              ご利用可能ポイント {points}pt
            </StyledTypography>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </ThemeProvider>
  );
}

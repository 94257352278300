import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Button, createTheme, Fab, makeStyles, ThemeProvider, withStyles } from '@material-ui/core';

const theme = createTheme({
      palette: {
        primary: {
          light: '#ffffff',
          main: '#ffffff',
          dark: '#ffffff',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#ff7f50',
          main: '#ff7f50',
          dark: '#ff7f50',
          contrastText: '#ffffff',
        },
      },
    });

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  fab: {
    margin: theme.spacing(2),
  },
}));

const FloatButton = withStyles({
  root: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    // color: '#ffff',
    // backgroundColor: theme.palette.primary.main,
    // '&:hover': {
    //   backgroundColor: theme.palette.primary.light,
    // },
    // '&:active': {
    //   backgroundColor: theme.palette.primary.dark,
    // },
  },
})(Button);

const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

const FloatingBtn = () => {
    const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
    <FloatButton className={classes.FloatButton} >
      <Fab className={classes.fab} color='secondary'>
        <PublishIcon fontSize="large" onClick={returnTop} />
        </Fab>
   </FloatButton>
   </ThemeProvider>
  )
};
export default FloatingBtn;

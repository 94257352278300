/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Client from "graphql-js-client";
import typeBundle from "./types";
import "./app.css";
import { CookiesProvider } from "react-cookie";
import ScrollToTop from "./utils/ScrollToTop";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const client = new Client(typeBundle, {
  url: "https://iwabuchi-shouten.myshopify.com/api/graphql",
  fetcherOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token": "3575755f968079817368793d9c87e327",
    },
  },
});

ReactDOM.render(
  <CookiesProvider>
    <App client={client} />
  </CookiesProvider>,
  document.getElementById("root")
);

const firebaseConfig = {
  apiKey: "AIzaSyBlbr_zLWSxikNekgIDJDCXNGrsRq4qxco",
  authDomain: "leafcafe-4fe64.firebaseapp.com",
  projectId: "leafcafe-4fe64",
  storageBucket: "leafcafe-4fe64.appspot.com",
  messagingSenderId: "760083274876",
  appId: "1:760083274876:web:0e2cf4c27085ef05dbfdf9",
  measurementId: "G-2DR6JCYXPP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
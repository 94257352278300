import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  createTheme,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  ThemeProvider,
  withStyles,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { FaCoins } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import Picon from "../img/parts/icons8-円p-80.png";
import slide1 from "../img/slide/TOPimg.jpg";
import slide2 from "../img/slide/TOPimg2.png";
import slide3 from "../img/slide/TOPimg_deme.png";
import slide_elanda from "../img/slide/TOPimg_elanda.png";
import slide_point from "../img/slide/TOPimg_point.png";
// import slide5 from '../img/slide/slide5.png';
import line from "../img/parts/line.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss";
import FloatingBtn from "../modules/assets/FloatingButton";
import ButtonAppBar from "../modules/AppBar";
import Footer from "../modules/Footer";
import { Link } from "react-router-dom";
import { useProducts } from "../../controllers/services/UseProducts";
import Header from "../modules/Header";
import Product from "../modules/Product";
import Recomend from "../modules/Recomend";
import LoadingBubbleOrganisms from "../organisms/LoadingBubbleOrganisms";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "メイリオ",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#89B929",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
      width: "cover",
    },

    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  offset: {
    ...theme.mixins.toolbar,
    flexGrow: 1,
  },
  grid: {
    margin: "auto",
  },
  category: {
    margin: theme.spacing(1.5),
  },
  line: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  cartGrid: {
    textAlign: "center",
  },
  cardWrap: {
    margin: "40px auto 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  typography: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  text: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: "8px",
    paddingBottom: "8px",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 45,
  },
  faIcon: {
    marginRight: theme.spacing(1),
    fontSize: 40,
  },
  bodyGrid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
}));

const StyledButton = withStyles({
  root: {
    fontSize: 14,
    padding: "12px 16px",
    width: 200,
    lineHeight: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.7,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
})(Button);

const StyledCardActions = withStyles({
  root: {
    display: "inline-flex",
  },
})(CardActions);

const StyledContainer = withStyles({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#FFFBF1",
  },
})(Container);

const StyledCard = withStyles({
  root: {
    backgroundColor: "#FFFBF1",
    outlineColor: "#ffeaac",
    borderColor: "#ffeaac",
    borderRadius: "10px",
    borderWidth: "8px",
  },
})(Card);

const StyledTypography = withStyles({
  root: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})(Typography);

const StyledPaper = withStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "20px",
    margin: theme.spacing(2),
    width: 220,
    color: theme.palette.primary.contrastText,
    backgroundColor: "#ffffff",
  },
})(Paper);

const collect = `お支払方法にて、現在「代引き」をご使用いただけません。\n近日中にご使用いただけるようになりますが、それまではクレジット決済、もしくは後払いをご使用ください。`;
const security = `セキュリティのため、購入手続きをするには再度ログインが必要となります。\nお手元にwith LEAFアプリのログインメールアドレスとパスワードをご用意ください。`;
const text =
  "当ウェブサイトは、健康管理アプリ『with LEAF』ご利用者様専用のオンラインショップです。\n\n「カラダ整う、ココロ喜ぶ」をコンセプトに、カラダやココロに優しい商品をラインナップいたしました。\nさらに、千葉県を中心に100年以上活動してきた当社だからこそご紹介できる、地元の商品などもご用意いたしました。\n\nさあ、美味しい健康習慣を始めましょう。";
const shippingText = `税込4,980円*以上の\nお買い上げで送料無料`;
const noteText = `*ポイント値引き後の合計金額`;
const pointText = `withLEAFで貯めた\nポイントを値引きで使用`;
const delivelyText = `最短翌営業日発送`;

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Index = () => {
  const classes = useStyles();
  const { useGetProducts } = useProducts();
  const { data, isLoading, isFetching, isError, isFetched } = useGetProducts();
  // !Object.keys(data).length
  // if (isFetching) {
  //   return (
  //     <div>
  //       <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
  //     </div>
  //   );
  // }
  if (isLoading) {
    return <div>
      <Typography>データ取得中...</Typography>
      <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
    </div>;
  }
  if (isError) {
    return <div>情報取得に失敗しました。再度画面を読み込んでください。</div>;
  }
  if (data) {
    if ( data[0] == undefined ){
      return  <div>
        <Typography>データ取得中...</Typography>
        <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
      </div>;
    } else {
    const recomendProducts = data[0].collections[6].node.products.edges;
    return (
      <ThemeProvider theme={theme}>
        <ButtonAppBar />
        <Grid container alignItems="center">
          <Grid item className={classes.grid} xs={12}>
            <div>
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 3000,
                }}
                navigation={true}
                pagination={{ clickable: true }}
                breakpoints={{
                  700: {
                    slidesPerView: 1.5,
                    slidesPerGroup: 1,
                  },
                }}
              >
                <div>
                  <SwiperSlide>
                    <img src={slide1} className="Top__img" alt="LEAFcafe" />
                  </SwiperSlide>
                </div>
               <SwiperSlide>
                  <Link
                    to={{
                      pathname: "/page",
                      search: `handle=howtouse_points`,
                    }}
                    className="Link"
                  >
                    <img src={slide_point} className="Top__img" alt="" />
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to={{
                      pathname: "/products",
                      search: `cid=8`,
                    }}
                    className="Link"
                  >
                    <img src={slide_elanda} className="Top__img" alt="" />
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to={{
                      pathname: "/product",
                      search: `handle=venetron25plus`,
                    }}
                    className="Link"
                  >
                    <img src={slide2} className="Top__img" alt="" />
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to={{
                      pathname: "/product",
                      search: `handle=demecal_cancer_woman`,
                    }}
                    className="Link"
                  >
                    <img src={slide3} className="Top__img" alt="" />
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {/* <Grid item xs={11}>
        <StyledCard className="Card-wrapper" variant="outlined">
          <CardContent className={classes.card}>
            <div style={{whiteSpace: 'pre-line'}}>
              <Typography variant="h6" >
                【 お知らせ 】
              </Typography>
              <Typography variant="body1">
                {collect}
              </Typography>
            </div>
          </CardContent>
        </StyledCard>
        </Grid> */}
          <Grid item xs={11}>
            <Hidden xsDown>
              <StyledCard className="Card-wrapper" variant="outlined">
                <CardContent className={classes.card}>
                  <div style={{ whiteSpace: "pre-line" }}>
                    <Typography variant="h6">【 お知らせ 】</Typography>
                    <Typography variant="body1">{security}</Typography>
                  </div>
                </CardContent>
              </StyledCard>
            </Hidden>
            <div className="Title-wrapper">
              <h2 className="head_test">PickUp おすすめ商品</h2>
            </div>
            <div className="Category-wrapper">
              {recomendProducts.map((product) => {
                return (
                  <Recomend
                    key={product.node.id.toString()}
                    product={product}
                  />
                );
              })}
            </div>
            <div className="Title-wrapper">
              <h2 className="head_test">Category 商品カテゴリー</h2>
            </div>
            <div className="Category-wrapper">
              <Box className="Category__img">
                <StyledTypography variant="h5">
                  食事の質を上げたい方に
                </StyledTypography>
                <img src={line} className={classes.line} alt="罫線" />
                <Link
                  to={{
                    pathname: "/products",
                    search: `cid=1`,
                  }}
                >
                  <img src={data[0].collections[1].node.image.url} alt="" />
                </Link>
              </Box>
              <Box className="Category__img">
                <StyledTypography variant="h5">
                  健康に過ごしたい方に
                </StyledTypography>
                <img src={line} className={classes.line} alt="罫線" />
                <Link
                  to={{
                    pathname: "/products",
                    search: `cid=2`,
                  }}
                >
                  <img src={data[0].collections[2].node.image.url} alt="" />
                </Link>
              </Box>
              <Box className="Category__img">
                <StyledTypography variant="h5">
                  千葉県ならではの商品
                </StyledTypography>
                <img src={line} className={classes.line} alt="罫線" />
                <Link
                  to={{
                    pathname: "/products",
                    search: `cid=3`,
                  }}
                >
                  <img src={data[0].collections[3].node.image.url} alt="" />
                </Link>
              </Box>
              <Box className="Category__img">
                <StyledTypography variant="h5">送料無料商品</StyledTypography>
                <img src={line} className={classes.line} alt="罫線" />
                <Link
                  to={{
                    pathname: "/products",
                    search: `cid=4`,
                  }}
                >
                  <img src={data[0].collections[4].node.image.url} alt="" />
                </Link>
              </Box>
            </div>
          </Grid>
        </Grid>
        <div className="Title-wrapper">
          <h2 className="head_test">AboutUs LEAFcafeについて</h2>
        </div>
        <StyledContainer>
          <Grid container className={classes.root} justifyContent="center">
            <Grid item className={classes.bodyGrid} xs={10}>
              {/* <Typography variant="h5" component="h5"　>
                  当ショップについて
                </Typography> */}
              <div style={{ whiteSpace: "pre-line" }}>
                <div className="text-align-switch">
                  <Typography variant="body1" className={classes.typography}>
                    {text}
                  </Typography>
                </div>
                <Grid container justifyContent="center">
                  <StyledPaper>
                    <LocalShippingIcon
                      className={classes.icon}
                      style={{ color: theme.palette.secondary.main }}
                    />
                    <Typography className={classes.text}>
                      {shippingText}
                    </Typography>
                    <Typography variant="caption">{noteText}</Typography>
                  </StyledPaper>
                  <StyledPaper>
                    <FaCoins
                      className={classes.faIcon}
                      style={{ color: theme.palette.secondary.main }}
                    />
                    <Typography className={classes.text}>
                      {pointText}
                    </Typography>
                  </StyledPaper>
                  <StyledPaper>
                    <BsClockHistory
                      className={classes.faIcon}
                      style={{ color: theme.palette.secondary.main }}
                    />
                    <Typography className={classes.text}>
                      {delivelyText}
                    </Typography>
                  </StyledPaper>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </StyledContainer>
        <FloatingBtn />
        <Footer />
      </ThemeProvider>
    );
  }}
};

export default Index;

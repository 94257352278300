import { commonHttpClient } from "./commonHttpClient";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { useToast } from "../../components/molecules/toastMolecules";
import LoadingBubbleOrganisms from "../../components/organisms/LoadingBubbleOrganisms";

export const useCheckout = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const getCheckout = async () => {
    let checkout;
    await http
      .get("/checkout")
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウト取得失敗:", err.response);
      });
    return checkout.data;
  };

  const useGetCheckout = () =>
    useQuery("CheckoutInfo", getCheckout, {
       refetchOnWindowFocus: false,
       staleTime: 3000,
      });

  const createCheckout = async () => {
    let checkout;
    await http
      .post("/checkout")
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウト作成失敗:", err.response);
      });
    return checkout;
  };

  const updateCheckout = async ({ lineItems, checkoutId }) => {
    let checkout;
    await http
      .patch("/checkout", {
        lineItems,
        checkoutId,
      })
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウトの更新失敗:", err.response);
      });
    return checkout.data;
  };

  const addItemCheckout = async ({ lineItems, checkoutId }) => {
    let checkout;
    await http
      .patch("/checkout/add", {
        lineItems,
        checkoutId,
      })
      .then((response) => {
        checkout = response;
      });
    return checkout;
  };

  const removeItemCheckout = async ({ lineItemIds, checkoutId }) => {
    let checkout;
    await http
      .patch("/checkout/remove", {
        checkoutId,
        lineItemIds,
      })
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウトの更新失敗:", err.response);
      });
    return checkout;
  };

  const deliveryTimeCheckout = async ({ checkoutId, deliveryTime }) => {
    let checkout;
    await http
      .patch("/checkout/delivery_time", {
        checkoutId,
        deliveryTime,
      })
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウトの更新失敗:", err.response);
      });
    
    return checkout.data;
  };

  const pointsCheckout = async ({ checkoutId, points, pointCode }) => {
    let checkout;
    await http
      .patch("/checkout/points", {
        checkoutId,
        points,
        pointCode,
      })
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウトの更新失敗:", err.response);
      }); 
    return checkout.data;
  };

  const deletePointsCheckout = async (checkoutId) => {
    let checkout;
    await http
      .patch("/checkout/delete_points", {
        checkoutId,
      })
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウトの更新失敗:", err.response);
      });
    return checkout.data;
  };

  const addShippingCheckout = async (
    checkoutId,
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    lastName,
    phone,
    province,
    zip,
  ) => {
    let checkout;
    await http
      .patch("/checkout/ship_address", {
        checkoutId,
        address1,
        address2,
        city,
        company,
        country,
        firstName,
        lastName,
        phone,
        province,
        zip,
      })
      .then((response) => {
        checkout = response;
      })
      .catch((err) => {
        console.debug("チェックアウトの更新失敗:", err.response);
      });
    return checkout.data;
  };

  const useCreateCheckout = () =>
    useMutation(createCheckout, {
      onSuccess: async () => {
        setTimeout(() => {
          queryClient.invalidateQueries("CheckoutInfo");
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            "error",
            "チェックアウト作成に失敗しました",
            e.message,
            3000
          );
          throw e;
        }
      },
    });

  const useUpdateCheckout = () =>
    useMutation(updateCheckout, {
      onSuccess: async () => {
        setTimeout(() => {
          queryClient.invalidateQueries("CheckoutInfo");
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            "error",
            "チェックアウトの更新に失敗しました",
            e.message,
            3000
          );
          throw e;
        }
      },
    });

  const useAddItemCheckout = () =>
    useMutation(addItemCheckout, {
      onSuccess: async () => {
        setTimeout(() => {
          queryClient.invalidateQueries("CheckoutInfo");
        }, 300);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            "error",
            "チェックアウトの更新に失敗しました",
            e.message,
            3000
          );
          throw e;
        }
      },
    });
  
// const useAddItemCheckout = () =>
//   useMutation(addItemCheckout, {
//     onMutate: async newCheckoutInfo => {
//       // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
//       await queryClient.cancelQueries('CheckoutInfo')
//       const previousCheckoutInfo = queryClient.getQueryData('CheckoutInfo')
//       queryClient.setQueryData('CheckoutInfo', old => [...old, newCheckoutInfo])
//       return previousCheckoutInfo;
//     },
//     onSuccess:() => {
//         queryClient.invalidateQueries("CheckoutInfo");
//     },
//     onError: (err, newCheckoutInfo, context) => {
//       queryClient.setQueryData('CheckoutInfo', context. previousCheckoutInfo)
//       }
//   });

  const useRemoveItemCheckout = () =>
    useMutation(removeItemCheckout, {
      onSuccess: async () => {
        setTimeout(() => {
          queryClient.invalidateQueries("CheckoutInfo");
        }, 999);
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            "error",
            "チェックアウトの更新に失敗しました",
            e.message,
            3000
          );
          throw e;
        }
      },
    });

  const useDeliveryTimeCheckout = () =>
  useMutation(deliveryTimeCheckout, {
    onSuccess: async () => {
      setTimeout(() => {
        queryClient.invalidateQueries("CheckoutInfo");
      }, 999);
    },
    onError: (e) => {
      if (e instanceof Error) {
        showToast(
          "error",
          "チェックアウトの更新に失敗しました",
          e.message,
          3000
        );
        throw e;
      }
    },
  });

  const usePointsCheckout = () =>
    useMutation(pointsCheckout, {
      onSuccess: async (data) => {
        setTimeout(() => {
          queryClient.invalidateQueries("CheckoutInfo");
          // queryClient.setQueryData(["CheckoutInfo"],
          //   data
          // );
          // queryClient.setQueryData("CheckoutInfo", { exact: true });
        }, 999);
        form.reset();
      },
      onError: (e) => {
        if (e instanceof Error) {
          showToast(
            "error",
            "チェックアウトの更新に失敗しました",
            e.message,
            3000
          );
          throw e;
        }
      },
    });

  const useDeletePointsCheckout = () =>
  useMutation(deletePointsCheckout, {
    onSuccess: async () => {
      setTimeout(() => {
        queryClient.invalidateQueries("CheckoutInfo");
      }, 999);
    },
    onError: (e) => {
      if (e instanceof Error) {
        showToast(
          "error",
          "チェックアウトの更新に失敗しました",
          e.message,
          3000
        );
        throw e;
      }
    },
  });

  return {
    getCheckout,
    createCheckout,
    // updateCheckout,
    addItemCheckout,
    removeItemCheckout,
    deliveryTimeCheckout,
    pointsCheckout,
    deletePointsCheckout,
    useGetCheckout,
    useCreateCheckout,
    useUpdateCheckout,
    useAddItemCheckout,
    useRemoveItemCheckout,
    useDeliveryTimeCheckout,
    usePointsCheckout,
    useDeletePointsCheckout,
  };
};

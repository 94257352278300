import React from "react";
import Product from "../modules/Product";
import Container from "@material-ui/core/Container";
import {
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FloatingBtn from "../modules/assets/FloatingButton";
import { useProducts } from "../../controllers/services/UseProducts";
import { Link, useHistory } from "react-router-dom";
import LoadingBubbleOrganisms from "../organisms/LoadingBubbleOrganisms";
import { useQueryParams } from "../../utils/useQueryParams";
import ButtonAppBar from "../modules/AppBar";
import Footer from "../modules/Footer";

const theme = createTheme({
  button: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#fff9f5",
      main: "#89B929",
      dark: "#915da3",
      contrastText: "#ffffff",
    },
  },
});

const StyledContainer = withStyles({
  root: {
    fontSize: "large",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
})(Container);

const Products = (props) => {
  const { useGetProducts } = useProducts();
  const { data: Data, isLoading } = useGetProducts();

  const queryParameters = useQueryParams();
  const cid = queryParameters.get("cid");
  const history = useHistory();

  // const [categoryName, setCategoryName] = React.useState(cid);
  // const [category, setCategory] = React.useState(cid);
  const handleChange = (event) => {
    // setCategoryName(event.target.value);
    // setCategory(event.target.value);
    history.push(`/products/?cid=${event.target.value}`);
  };

  if (isLoading) {
    return (
      <div>
        <Typography>データ取得中...</Typography>
        <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
      </div>
    );
  }
  if (Data) {
    const collection_Array = Data[0].collections;
    let product_Array = collection_Array[cid].node.products.edges;
    return (
      <ThemeProvider theme={theme}>
        <ButtonAppBar />
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <div>
              <div>
                <StyledContainer>
                  <FormControl style={{ minWidth: 200 }}>
                    <InputLabel id="simple-select-label">
                      商品カテゴリー
                    </InputLabel>

                    <Select
                      labelId="simple-select-label"
                      id="simple-select"
                      value={cid}
                      onChange={handleChange}
                      color="secondary"
                    >
                      <MenuItem value={0}>全商品</MenuItem>
                      <MenuItem value={1}>食事の質を上げたい方に</MenuItem>
                      <MenuItem value={2}>
                        健康に過ごしたい方に
                      </MenuItem>
                      <MenuItem value={3}>千葉県の商品</MenuItem>
                      <MenuItem value={4}>送料無料</MenuItem>
                    </Select>
                  </FormControl>
                </StyledContainer>
              </div>
              <div className="Product-wrapper">
                {product_Array.map((product) => {
                  return (
                    <Product
                      key={product.node.handle.toString()}
                      product={product}
                      isCartOpen={props.isCartOpen}
                    />
                  );
                })}
              </div>
              <FloatingBtn />
            </div>
          </Grid>
        </Grid>
        <Footer />
      </ThemeProvider>
    );
  }
};

export default Products;

import { Card, CardContent, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',

  },
  card: {
    position: 'absolute',
    paddingBottom: "0px",
    alignContent: 'center',
    backgroundColor: '#dd0000',
    // opacity: 0.8,
    top: '90%',
    left: '0%',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
    zIndex: theme.zIndex.drawer + 1,
  },
  cardContet: {
    '&:last-child': {
      paddingBottom: 4,
    },
    padding: 4,
  },
  Typography: {
    margin: "4px",
  }
}));


function Recomend(props) {
  const classes = useStyles();
  const productPrice = props.product.node.variants.edges[0].node.price.amount;
  const productCAPrice = props.product.node.variants.edges[0].node.compareAtPrice.amount;

    return (
      <div className="Recomend">
        <div className={classes.root}>
        <Link
          to={{
            pathname: "/product",
            search: `?handle=${props.product.node.handle}`,
          }}>
            <img  src={props.product.node.images.edges[0].node.url} alt={` product shot`} />
            {
             (() => {
              if (productPrice != productCAPrice ) {
                const disount = ( 1 -  productPrice / productCAPrice ) * 100;
                return(
                <Paper
                  className={classes.card}
                  variant="outlined"
                  square>
                    <Typography variant="body1" className={classes.Typography}>
                      {Math.floor(disount)}％OFF
                    </Typography>
                </Paper> 
              )}
             })()}
            </Link>
            </div>
        <h5 className="Product__title" >{props.product.node.title}</h5>
        {/* <h4 className="Product__price" >{Number(this.props.product.node.variants.edges[0].node.price).toLocaleString()}円 (税込)</h4> */}
      </div>
    );
  }

  export default Recomend;
  

  
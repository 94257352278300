import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import ContactCheck from "./ContactCheck";
import ContactThanks from "./ContactThanks";
import ButtonAppBar from "../../modules/AppBar";
import Footer from "../../modules/Footer";
import FloatingBtn from "../../modules/assets/FloatingButton";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "メイリオ",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});
const StyledTableCell = withStyles({
  body: {
    fontSize: "small",
  },
})(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
    table: {
      minWidth: "auto",
    },
    formControl: {
      margin: theme.spacing(2),
      // minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  grid: {
    marginTop: 80,
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
  SubmitButton: {
    marginTop: theme.spacing(4),
    SndIcon: {
      marginLeft: theme.spacing(2),
    },
    FloatButton: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
}));
const validationSchema = Yup.object().shape({
  name: Yup.string().required("氏名を入力してください。"),
  res_mail_address: Yup.string()
    .required("返信用メールアドレスは必須です。")
    .test("res_mail_address", "無効なメールアドレスです", function (email) {
      const tester =
        /^[\w+/_?-](\.?[\w+/_?-])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      if (!email) return false;
      const emailParts = email.split("@");
      if (emailParts.length !== 2) return false;
      const account = emailParts[0];
      const address = emailParts[1];
      if (account.length > 64) return false;
      else if (address.length > 255) return false;
      const domainParts = address.split(".");
      if (
        domainParts.some(function (part) {
          return part.length > 63;
        })
      )
        return false;
      if (!tester.test(email)) return false;
      return true;
    }),
  phone_number: Yup.string()
    .min(10, "10桁以上で入力してください")
    .max(13, "13桁以内で入力してください")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "半角数字で正しい電話番号を入力してください"
    ),
  type: Yup.string(),
  body: Yup.string().required("問合せ詳細は必須です。"),
  company_name: Yup.string(),
});
const createData = (title, detail) => {
  return { title, detail };
};

export default function ContactForm() {
  const classes = useStyles();
  const StyledButton = withStyles({
    root: {
      fontSize: 12,
      padding: "10px 14px",
      lineHeight: 2,
      color: "#ffffff",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
      "&:active": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  })(Button);
  let initialName = "";
  let initialPhoneNumber = "";
  let initialMail = "";
  let initialType = "商品について";
  let initialBody = "";
  let initialComName = "";
  const {
    values,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: {
      name: initialName,
      phone_number: initialPhoneNumber,
      res_mail_address: initialMail,
      type: initialType,
      body: initialBody,
      company_name: initialComName,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });
  const rows = [
    createData("氏名", values.name),
    createData("メールアドレス", values.res_mail_address),
    createData("電話番号", values.phone_number),
    createData("御社名", values.company_name),
    createData("お問い合わせ内容", values.type),
    createData("お問い合わせ内容の詳細", values.body),
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);

  function onSubmit() {
    setIsOpen(true);
    // history.replace({
    //   pathname: "/contact/check",
    //   state: values,
    // });
  }
  function closeDialog() {
    setIsOpen(false);
  }
  function openDialog2() {
    setIsDone(true);
  }
  function closeDialog2() {
    resetForm();
    setIsDone(false);
  }
  return (
    <ThemeProvider theme={theme}>
      <ButtonAppBar />
      <Container component="main" maxWidth="sm">
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.grid}
        >
          {/* <Grid item className={classes.grid} xs={12} > */}
          <Divider className={classes.divider} />
          <Typography component="h1" variant="h4">
            お問い合わせフォーム
          </Typography>
          <Divider className={classes.divider} />

          <Typography variant="body1">
            こちらのフォームに必要事項をご入力のうえ、送信してください。
          </Typography>
          <Typography variant="body1">
            ３営業日を目安にご返信させていただきます。
          </Typography>
          <Box sx={{ height: 30 }}></Box>
          <Typography variant="caption">
            *のある欄は必須項目です。必ずご入力ください。
          </Typography>
          <Box noValidate sx={{ mt: 3, mb: 2 }} alignItems="center">
            <form className={classes.root} onSubmit={handleSubmit}>
              <TextField
                id="name"
                name="name"
                label="氏名"
                required
                variant="outlined"
                color="secondary"
                value={values.name}
                onChange={handleChange}
                error={touched.name && !!errors.name}
                helperText={!touched.name ? errors.name : ""}
                disabled={isOpen}
                className={classes.form}
              />
              <TextField
                id="res_mail_address"
                name="res_mail_address"
                label="メールアドレス"
                required
                variant="outlined"
                color="secondary"
                value={values.res_mail_address}
                onChange={handleChange}
                error={touched.res_mail_address && !!errors.res_mail_address}
                helperText={
                  !touched.res_mail_address ? errors.res_mail_address : ""
                }
                className={classes.form}
              />
              <TextField
                id="phone_number"
                name="phone_number"
                label="電話番号(任意)"
                variant="outlined"
                color="secondary"
                value={values.phone_number}
                onChange={handleChange}
                error={touched.phone_number && !!errors.phone_number}
                helperText={!touched.phone_number ? errors.phone_number : ""}
              />
              <TextField
                id="company_name"
                name="company_name"
                label="御社名(任意)"
                variant="outlined"
                color="secondary"
                value={values.company_name}
                onChange={handleChange}
              />
              <FormControl required className={classes.formControl}>
                <InputLabel id="select-title">お問い合わせ内容</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  label="お問い合わせ内容"
                  color="secondary"
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  <MenuItem value="商品について">商品について</MenuItem>
                  <MenuItem value="ご注文について">ご注文について</MenuItem>
                  <MenuItem value="返品や交換について">
                    返品や交換について
                  </MenuItem>
                  <MenuItem value="その他">その他</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="body"
                name="body"
                label="お問い合わせの詳細"
                variant="outlined"
                requied
                multiline
                rows={15}
                color="secondary"
                value={values.body}
                error={touched.body && !!errors.body}
                helperText={!touched.body ? errors.body : ""}
                onChange={handleChange}
              />
              <Box sx={{ height: 30 }}></Box>
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isValid}
              >
                内容を確認する
              </StyledButton>
            </form>
          </Box>
          {/* </Grid> */}
        </Grid>
      </Container>
      <ContactCheck
        isOpen={isOpen}
        values={values}
        closeDialog={closeDialog}
        openNext={openDialog2}
      />
      <ContactThanks isDone={isDone} closeDialog2={closeDialog2} />
      <FloatingBtn />
      <Footer />
    </ThemeProvider>
  );
}

import React, { createContext } from "react";


// interface CommonContext {
//   children: React.ReactNode;
// }


export const IsCartOpenContext = createContext({});

const CommonContext = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = React.useState(false);

  const value = {
    isCartOpen,
    setIsCartOpen,
  };

  return (
    <IsCartOpenContext.Provider 
     value={value}
     >
      {children}
    </IsCartOpenContext.Provider>
  );
  
};

export default CommonContext;

import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import LoadingBubbleOrganisms from "../../organisms/LoadingBubbleOrganisms";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Container,
  createTheme,
  Divider,
  Grid,
  TableHead,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import FloatingBtn from "../../modules/assets/FloatingButton";
import { useOrders } from "../../../controllers/services/UseOrders";
import { useQueryParams } from "../../../utils/useQueryParams";
import moment from "moment";
import "moment/locale/ja";
import ButtonAppBar from "../../modules/AppBar";
import Footer from "../../modules/Footer";

const theme = createTheme({
  typography: {
    fontFamily: [
      "ヒラギノ角ゴ ProN",
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "Hiragino Kaku Gothic ProN",
      "Meiryo",
      "メイリオ",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  grid: {
    margin: "auto",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  typography: {
    textAlign: "left",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    width: "100%",
    height: 4,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.dark,
  },
  FloatButton: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledTableCell = withStyles({
  root: {
    fontSize: 14,
    fontWeight: "bold",
  },
})(TableCell);

const StyledTypography = withStyles({
  root: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
})(Typography);
const DiscountTableCell = withStyles({
  root: {
    color: theme.palette.secondary.dark,
  },
})(TableCell);

const province_Array = [
  {
    "en": "Aichi",
    "jp": "愛知県"
  },
  {
    "en": "Akita",
    "jp": "秋田県"
  },
  {
    "en": "Aomori",
    "jp": "青森県"
  },
  {
    "en": "Chiba",
    "jp": "千葉県"
  },
  {
    "en": "Ehime",
    "jp": "愛媛県"
  },
  {
    "en": "Fukui",
    "jp": "福井県"
  },
  {
    "en": "Fukuoka",
    "jp": "福岡県"
  },
  {
    "en": "Fukushima",
    "jp": "福島県"
  },
  {
    "en": "Gifu",
    "jp": "岐阜県"
  },
  {
    "en": "Gunma",
    "jp": "群馬県"
  },
  {
    "en": "Hiroshima",
    "jp": "広島県"
  },
  {
    "en": "Hokkaidō",
    "jp": "北海道"
  },
  {
    "en": "Hyōgo",
    "jp": "兵庫県"
  },
  {
    "en": "Ibaraki",
    "jp": "茨城県"
  },
  {
    "en": "Ishikawa",
    "jp": "石川県"
  },
  {
    "en": "Iwate",
    "jp": "岩手県"
  },
  {
    "en": "Kagawa",
    "jp": "香川県"
  },
  {
    "en": "Yamanashi",
    "jp": "山梨県"
  },
  {
    "en": "Yamaguchi",
    "jp": "山口県"
  },
  {
    "en": "Kōchi",
    "jp": "高知県"
  },
  {
    "en": "Kumamoto",
    "jp": "熊本県"
  },
  {
    "en": "Kyōto",
    "jp": "京都府"
  },
  {
    "en": "Mie",
    "jp": "三重県"
  },
  {
    "en": "Yamagata",
    "jp": "山形県"
  },
  {
    "en": "Miyazaki",
    "jp": "宮崎県"
  },
  {
    "en": "Nagano",
    "jp": "長野県"
  },
  {
    "en": "Nagasaki",
    "jp": "長崎県"
  },
  {
    "en": "Nara",
    "jp": "奈良県"
  },
  {
    "en": "Niigata",
    "jp": "新潟県"
  },
  {
    "en": "Ōita",
    "jp": "大分県"
  },
  {
    "en": "Okayama",
    "jp": "岡山県"
  },
  {
    "en": "Okinawa",
    "jp": "沖縄県"
  },
  {
    "en": "Ōsaka",
    "jp": "大阪府"
  },
  {
    "en": "Saga",
    "jp": "佐賀県"
  },
  {
    "en": "Saitama",
    "jp": "埼玉県"
  },
  {
    "en": "Shiga",
    "jp": "滋賀県"
  },
  {
    "en": "Shimane",
    "jp": "島根県"
  },
  {
    "en": "Shizuoka",
    "jp": "静岡県"
  },
  {
    "en": "Tochigi",
    "jp": "栃木県"
  },
  {
    "en": "Tokushima",
    "jp": "徳島県"
  },
  {
    "en": "Tottori",
    "jp": "鳥取県"
  },
  {
    "en": "Toyama",
    "jp": "富山県"
  },
  {
    "en": "Tōkyō",
    "jp": "東京都"
  },
  {
    "en": "Miyagi",
    "jp": "宮城県"
  },
  {
    "en": "Wakayama",
    "jp": "和歌山県"
  },
  {
    "en": "Kanagawa",
    "jp": "神奈川県"
  },
  {
    "en": "Kagoshima",
    "jp": "鹿児島県"
  },
  {
    "en": "",
    "jp": ""
  }
];

function OrderDetailRow({ props }) {
  const classes = useStyles();
  const gid = props;
  const { getOrderDetail } = useOrders();
  const { data, isLoading, isError } = useQuery(
    ["orderDetail", gid],
    () => getOrderDetail(gid),
    { retry: 1, refetchOnWindowFocus: false }
  );
  if (isLoading) {
    return (
      <div>
        <Typography>購入データ取得中...少々お待ちください</Typography>
        <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
      </div>
    );
  }
  if (isError) {
    return (
      <TableRow key="empty" textAlign="center">
        ご注文履歴がありません。
      </TableRow>
    );
  }
  if (data) {
    console.log(data);
    let subt = 0;
    data.items.forEach((elm) => {
      subt += Number(elm.price) * Number(elm.quantity);
    });
    function province() {
      const prov = province_Array.find((e) => e.en == data.shipAddress.province );
        return prov.jp; 
    };
    return (
      <div>
        <Typography variant="body1" className={classes.typography}>
          ご注文年月日：　
          {moment
            .utc(data.createdAt)
            .local()
            .format("YYYY年MM月DD日(dd) HH:mm")
            .toString()}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          お支払い方法：　{data.paymentGateway}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          配送状況：　
          {data.status === "FULFILLED"
            ? "発送済み"
            : data.status === "PARTIALLY_FULFILLED"
            ? "一部発送済み"
            : data.status === "UNFULFILLED"
            ? "未発送"
            : data.status}
        </Typography>
        <form>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>ご注文番号：　{data.name}</TableCell>
                  {/* <StyledTableCell align="right"></StyledTableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell>商品名</TableCell>
                  <TableCell align="right">発送状況</TableCell>
                  <TableCell align="right">単価</TableCell>
                  <TableCell align="right">数量</TableCell>
                  <TableCell align="right">金額</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.items.map((row) => {
                  const pr = Number(row.price);
                  const quant = Number(row.quantity);
                  const subt = pr * quant;
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="right">
                        {row.status === "fulfilled"
                          ? "発送済み"
                          : row.status === "partial"
                          ? `${row.fulfillQuantity}個 発送済み`
                          : row.status === "unfulfilled"
                          ? "未発送"
                          : row.status}
                      </TableCell>
                      <TableCell align="right">
                        {pr.toLocaleString()}円
                      </TableCell>
                      <TableCell align="right">
                        {quant.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {subt.toLocaleString()}円
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell rowSpan={6} />
                  <TableCell rowSpan={6} />
                  <TableCell colSpan={2}>小計</TableCell>
                  <TableCell align="right">
                    {Math.floor(subt).toLocaleString()}円
                  </TableCell>
                </TableRow>
                {data.discountPrice > 0 ? (
                  <TableRow>
                    <DiscountTableCell colSpan={2}>値引総額</DiscountTableCell>
                    <DiscountTableCell align="right">
                      {"-" + Math.floor(data.discountPrice).toLocaleString()}円
                    </DiscountTableCell>
                  </TableRow>
                ) : (
                  <div></div>
                )}
                {data.shipLine ? (
                  <TableRow>
                    <TableCell colSpan={2}>送料</TableCell>
                    <TableCell align="right">
                      {Number(data.shipLine).toLocaleString()}円
                    </TableCell>
                  </TableRow>
                ) : (
                  <div></div>
                )}
                <TableRow>
                  <StyledTableCell colSpan={2}>合計金額</StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(data.totalPrice).toLocaleString()}円
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </form>
        <StyledTypography variant="body1" className={classes.typography}>
          ご注文者様
        </StyledTypography>
        <Typography variant="body1" className={classes.typography}>
          お名前：{data.customer.lastName + data.customer.firstName}
        </Typography>
        <StyledTypography variant="body1" className={classes.typography}>
          お届け先
        </StyledTypography>
        <Typography variant="body1" className={classes.typography}>
          お名前：{data.shipAddress.lastName + data.shipAddress.firstName}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          〒 {data.shipAddress.zip}
        </Typography>
        {province() ? (
          <Typography variant="body1" className={classes.typography}>
            {province()}
          </Typography>
        ) : (
          <div></div>
        )}
        <Typography variant="body1" className={classes.typography}>
          {data.shipAddress.city}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          {data.shipAddress.address1}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          {data.shipAddress.address2}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          お電話番号：{data.shipAddress.phone}
        </Typography>
      </div>
    );
  }
}

export default function MyOrder() {
  const queryParameters = useQueryParams();
  const gid = queryParameters.get("gid");
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
     <ButtonAppBar/>
      <Container component="main" maxWidth="md">
        <Grid container justifyContent="center">
          <Grid item className={classes.grid}>
            <Divider className={classes.divider} />
            <Typography component="h2" variant="h4">
              ご注文履歴
            </Typography>
            <Divider className={classes.divider} />
            <OrderDetailRow key={gid} props={gid} />
            <Link
              to="/myaccount/myorders"
              className="btn-border-bottom"
              style={{
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
              }}
            >
              ご注文履歴一覧に戻る
            </Link>
            <FloatingBtn />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

import { commonHttpClient } from "./commonHttpClient";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useToast } from "../../components/molecules/toastMolecules";

export const useUser = () => {
  const { http } = commonHttpClient();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const getUser = async () => {
    let userData = {};
    await http
      .get("/user/me")
      .then((response) => {
        userData = response.data;
      })
      .catch((err) => {
        console.debug("ユーザー情報取得失敗:", err.response);
      });
    return userData;
  };

  const useGetUser = () =>
    useQuery("myAccount", getUser, {
      refetchOnWindowFocus: false,
      staleTime: 300,
    });
    
  const getUserPoints = async () => {
    let userPoints = {};
    await http
      .get("user/points")
      .then((response) => {
        userPoints = response.data;
      })
      .catch((err) => {
        console.debug("ユーザーポイント照会失敗", err.response);
      });
    return userPoints;
  };

  const useGetUserPoints = () => useQuery("myPoints", getUserPoints);

  return {
    useGetUser,
    useGetUserPoints,
  };
};

import React from "react";
import Container from "@material-ui/core/Container";
import {
  createTheme,
  Grid,
  Hidden,
  makeStyles,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import FloatingBtn from "../modules/assets/FloatingButton";
import { Link, useHistory } from "react-router-dom";
import LoadingBubbleOrganisms from "../organisms/LoadingBubbleOrganisms";
import { useQueryParams } from "../../utils/useQueryParams";
import ButtonAppBar from "../modules/AppBar";
import Footer from "../modules/Footer";
import { usePage } from "../../controllers/services/UsePage";
import { getPageInfo } from "../../controllers/services/api";
import { useQuery } from "react-query";


const theme = createTheme({
  button: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#fff9f5",
      main: "#9b72b0",
      dark: "#915da3",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  typography: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  bodyGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleGrid: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}))
  
const StyledContainer = withStyles({
  root: {
    fontSize: "large",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
})(Container);

const StyledTypography = withStyles({
  root: {
    // margin: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
})(Typography);


function PageDetail(props) {
  const classes = useStyles();
  const pageDescriptionHtml = props.Data.body;

  return(
    <div>
    <Hidden xsDown>
    <StyledContainer />
    </Hidden>
    <Grid container justifyContent="center">
      {/* <Grid item className={classes.titleGrid} xs={11}>
        <StyledTypography
          className={classes.typography}
          component="h1"
          variant="h5"
        >
          {props.Data.title}
        </StyledTypography>
      </Grid> */}
      <Grid item className={classes.bodyGrid} xs={12}>
        <div dangerouslySetInnerHTML={{ __html: pageDescriptionHtml }} />
      </Grid>
    </Grid>
    </div>
  )
}


const Page = () => {
  const { useGetPages } = usePage();
  const { 
    data, 
    isLoading, 
    isError,
  } = useGetPages();

  const queryParameters = useQueryParams();
  const handle = queryParameters.get("handle");
  const history = useHistory();

  const {
    isLoading: PageLoading,
    data: PageData,
    isError: pageIsError,
  } = useQuery(
    ["pageInfo", handle],
    () => getPageInfo(String(handle)),
    { retry: false }
  );

  if (isLoading || PageLoading) {
    return (
      <div>
        <Typography>データ取得中...</Typography>
        <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
      </div>
    );
  }
  if (data || PageData ) {
    return (
      <ThemeProvider theme={theme}>
        <ButtonAppBar/>
        <Container maxWidth="md">
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <PageDetail
                key={PageData.handle.toString()}
                Data={PageData}
              />
            </Grid>
          </Grid>
        </Container>
        <FloatingBtn />
        <Footer />
      </ThemeProvider>
    );
  }
};

export default Page;
